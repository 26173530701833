<template>
  <div class="container talent">
    <Loader
      v-if="$apollo.queries.artists.loading && !isLoadingMore"
      color="#FF035A"
      size="50"
    />
    <div v-else class="relative pt-6 content">
      <div class="left-panel">
        <div class="filters">
          <div class="search">
            <div class="icon">
              <img src="../assets/icons/Search.svg" />
            </div>
            <input
              type="search"
              v-model="searchQuery"
              placeholder="Search by name"
            />
          </div>
          <CustomSelect
            default="Sort: A to Z"
            @setValue="value => (sortBy = value)"
            :options="[
              {
                value: 'az',
                label: 'Sort: A to Z'
              },
              {
                value: 'za',
                label: 'Sort: Z to A'
              }
            ]"
          />
        </div>

        <div
          v-if="filteredArtists.length != 0"
          class="flex items-center justify-center w-full mb-5"
        >
          <div class="add-collaborator">
            <button
              class="flex items-center gap-1 primary"
              @click="addingCollaborator = true"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-user-plus"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="#ffffff"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0" />
                <path d="M16 19h6" />
                <path d="M19 16v6" />
                <path d="M6 21v-2a4 4 0 0 1 4 -4h4" />
              </svg>
              Add New Collaborator
            </button>
          </div>
        </div>

        <div class="artists-list-container">
          <div class="px-6 artists-list" @scroll="handleScroll">
            <div class="cards">
              <!-- <h2
                class="mb-4 text-xl font-bold text-left uppercase "
                style="width: calc(100%);"
              >
                Active Collaborators
              </h2> -->
              <div
                v-if="filteredArtists.length == 0"
                class="flex items-center justify-center w-full my-5"
              >
                <div class="add-collaborator">
                  <button
                    class="flex items-center gap-1 primary"
                    @click="addingCollaborator = true"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon icon-tabler icon-tabler-user-plus"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="#ffffff"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0" />
                      <path d="M16 19h6" />
                      <path d="M19 16v6" />
                      <path d="M6 21v-2a4 4 0 0 1 4 -4h4" />
                    </svg>
                    Add New Collaborator
                  </button>
                </div>
              </div>

              <div
                v-for="artist in filteredArtists"
                :key="artist.id"
                class="artist-card"
                :class="{ active: artist.id === active }"
                @click="goToContact(artist.id)"
              >
                <div class="avatar">
                  <div class="picture" v-if="artist.avatar_url">
                    <img :src="artist.avatar_url" />
                  </div>
                  <div class="initials" v-else>
                    {{ artist.name | initials }}
                  </div>
                </div>
                <div class="name">
                  {{ artist.name }}
                </div>
              </div>
            </div>
            <div
              class="cards pending"
              v-if="pendingContacts && pendingContacts.length > 0"
            >
              <h2
                class="mb-4 text-xl font-bold text-left uppercase "
                style="width: calc(100%);"
              >
                Pending Collaborators
              </h2>

              <div
                class="flex flex-col items-start justify-center w-full gap-5"
                style="width: calc(100%);"
              >
                <div
                  v-for="(pendingArtist, i) in pendingContacts"
                  :key="pendingArtist.name + '_pending_' + i"
                  @click="goToContact(pendingArtist.id)"
                  class="flex w-full artist-card"
                  :class="{ active: pendingArtist.id === active }"
                  style="margin-bottom: 0; width: 100%;"
                >
                  <div class="avatar">
                    <div class="picture" v-if="pendingArtist.avatar_url">
                      <img :src="pendingArtist.avatar_url" />
                    </div>
                    <div class="initials" v-else>
                      {{ pendingArtist.name | initials }}
                    </div>
                  </div>
                  <div class="flex-1 truncate name">
                    {{ pendingArtist.name }}
                  </div>
                  <div
                    v-if="pendingArtist.invited_artist_email === user.email"
                    class="flex items-center justify-center gap-1"
                  >
                    <button
                      @click="handleContact(pendingArtist.contact_id, 'reject')"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="icon icon-tabler icon-tabler-x"
                        width="18"
                        height="18"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="#FF035A"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M18 6l-12 12" />
                        <path d="M6 6l12 12" />
                      </svg>
                    </button>
                    <button
                      @click="
                        handleContact(pendingArtist.contact_id, 'accepted')
                      "
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="icon icon-tabler icon-tabler-check"
                        width="18"
                        height="18"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="#00b341"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M5 12l5 5l10 -10" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <span
                style="width: calc(100% - 60px);"
                class="w-full h-0.5 bg-gray-300 my-5"
              />
            </div>
            <div v-if="hasMoreItems" class="scroll-to-load">
              <span class="load-more-text">Scroll to load more</span>
              <Loader v-if="isLoadingMore" color="#FF035A" size="30" />
            </div>
          </div>
        </div>
      </div>
      <div class="middle-gutter">
        <svg
          v-if="active"
          style="margin-top: 185px;"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.0306 12.5312L9.53063 20.0312C9.42573 20.1362 9.29204 20.2078 9.14648 20.2367C9.00092 20.2657 8.85002 20.2509 8.71291 20.1941C8.57579 20.1372 8.45861 20.041 8.37621 19.9176C8.29381 19.7941 8.24988 19.649 8.25 19.5006V4.50059C8.24988 4.35217 8.29381 4.20705 8.37621 4.0836C8.45861 3.96016 8.57579 3.86394 8.71291 3.80712C8.85002 3.75031 9.00092 3.73546 9.14648 3.76444C9.29204 3.79343 9.42573 3.86496 9.53063 3.96997L17.0306 11.47C17.1004 11.5396 17.1557 11.6223 17.1934 11.7134C17.2312 11.8044 17.2506 11.902 17.2506 12.0006C17.2506 12.0992 17.2312 12.1967 17.1934 12.2878C17.1557 12.3788 17.1004 12.4616 17.0306 12.5312Z"
            fill="#343330"
          />
        </svg>
      </div>
      <div class="right-panel-artist">
        <ContactPreview :artists="activeContacts" />
      </div>
    </div>
    <add-collaborator
      v-if="addingCollaborator"
      @close="success => collaboratorAdded(success)"
      :host_id="host_id"
    />
  </div>
</template>

<script>
import ContactPreview from "@/components/ContactPreview.vue";
import CustomSelect from "@/components/Select.vue";
import Loader from "@/components/Loader.vue";

import AddCollaborator from "../components/modals/AddCollaborator.vue";
import SEARCH_ARTISTS_EXISTING from "@/api/queries/SEARCH_ARTISTS_EXISTING.gql";
import SYNC_CONTACTS from "@/api/mutations/SYNC_CONTACTS.gql";

export default {
  name: "Contacts",
  components: {
    ContactPreview,
    CustomSelect,
    Loader,
    AddCollaborator
  },
  data() {
    return {
      searchQuery: "",
      sortBy: "az",
      addingCollaborator: false,
      offset: 0,
      isLoadingMore: false,
      hasMoreItems: true
    };
  },
  computed: {
    active() {
      return this.$route.query.artist;
    },
    filteredArtists() {
      const multiple = this.sortBy === "az" ? 1 : -1;
      const currentArtist = this.$store.getters["account/getArtist"];

      console.log("Current Artist:", currentArtist);
      console.log(
        "Current Artist Connected Entities:",
        currentArtist?.connected_entities
      );

      // Create a map to track unique IDs
      const uniqueArtists = new Map();
      this.artists
        ?.filter(el => {
          console.log("Checking artist:", el.name, "entity_id:", el.entity_id);
          return (
            el.name?.toLowerCase().includes(this.searchQuery.toLowerCase()) &&
            !el.pending &&
            !currentArtist?.name?.toLowerCase().includes(el.name?.toLowerCase())
          );
        })
        .forEach(artist => {
          if (!uniqueArtists.has(artist.id)) {
            uniqueArtists.set(artist.id, artist);
          }
        });

      const result = Array.from(uniqueArtists.values()).sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) return -1 * multiple;
        if (nameA > nameB) return 1 * multiple;
        return 0;
      });

      console.log("Filtered Artists:", result);
      return result;
    },
    pendingContacts() {
      const multiple = this.sortBy === "az" ? 1 : -1;

      const artists = this.artists.filter(
        el =>
          el.name?.toLowerCase().includes(this.searchQuery.toLowerCase()) &&
          el.pending
      );

      return artists.sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) return -1 * multiple;
        if (nameA > nameB) return 1 * multiple;
        return 0;
      });
    },
    activeContacts() {
      return this.artists.filter(el => el.id);
    },
    host_id() {
      return this.$store.getters["account/getArtistId"];
    },
    user() {
      return this.$store.getters["account/getUser"];
    }
  },
  filters: {
    initials(name) {
      return name
        ?.split(" ")
        .slice(0, 2)
        .map(el => el.charAt(0).toUpperCase())
        .join("");
    }
  },
  methods: {
    goToContact(id) {
      this.$router.push({ name: "Contacts", query: { artist: id } });
    },
    collaboratorAdded(success) {
      this.addingCollaborator = false;
      if (success) this.$apollo.queries.artists.refetch();
    },
    async handleContact(contactId, response) {
      const user = this.$store.getters["account/getUser"];

      const artistId = this.$store.getters["account/getArtistId"];

      const {
        data: { success }
      } = await this.$apollo.mutate({
        mutation: SYNC_CONTACTS,
        variables: {
          email: user.email,
          specific_artist_id: artistId,
          response,
          contact_id: contactId
        }
      });

      console.log(success);

      this.$apollo.queries.artists.refetch();
    },
    handleScroll(e) {
      const container = e.target;
      const scrollPosition = container.scrollTop + container.clientHeight;
      // Load more when user scrolls to 95% of the list (less aggressive)
      if (
        scrollPosition > container.scrollHeight * 0.95 &&
        !this.isLoadingMore &&
        this.hasMoreItems
      ) {
        this.loadMore();
      }
    },
    async loadMore() {
      this.isLoadingMore = true;
      this.offset += 10; // Increment offset by limit

      try {
        await this.$apollo.queries.artists.fetchMore({
          variables: {
            offset: this.offset
          },
          updateQuery: (previousResult, { fetchMoreResult }) => {
            if (!fetchMoreResult.search_existing_collaborators.length) {
              this.hasMoreItems = false;
              return previousResult;
            }

            // Create a map of existing IDs
            const existingIds = new Set(
              previousResult.search_existing_collaborators.map(
                artist => artist.id
              )
            );

            // Filter out duplicates from new results
            const newUniqueArtists = fetchMoreResult.search_existing_collaborators.filter(
              artist => !existingIds.has(artist.id)
            );

            // If after filtering duplicates we have no new items, mark as no more items
            if (newUniqueArtists.length === 0) {
              this.hasMoreItems = false;
              return previousResult;
            }

            return {
              search_existing_collaborators: [
                ...previousResult.search_existing_collaborators,
                ...newUniqueArtists
              ]
            };
          }
        });
      } finally {
        this.isLoadingMore = false;
      }
    }
  },
  apollo: {
    artists: {
      query: SEARCH_ARTISTS_EXISTING,
      variables() {
        return {
          artistId: this.$store.getters["account/getArtistId"],
          artistEmail: this.$store.getters["account/getUser"].email,
          accepted: false,
          limit: 10,
          offset: 0
        };
      },
      update: ({ search_existing_collaborators }) => {
        return search_existing_collaborators.map(el => ({
          ...el
        }));
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/_mixins.scss";

.talent {
  width: 100%;
  height: 100%;

  .content {
    display: grid;
    grid-template-columns: 400px 24px 1fr;
    width: 100%;
    height: 100%;

    .left-panel {
      height: 100%;
      background: white;
      border-radius: 10px;
      border: 1px solid #e2e2e2;
      max-height: calc(100vh - 90px);
      display: flex;
      flex-direction: column;

      .filters {
        display: flex;
        padding: 20px 30px;

        .search {
          flex: 1;
          display: flex;
          align-items: center;

          .icon {
            img {
              width: 20px;
              height: 20px;
            }
          }

          input {
            flex: 1;
            margin-left: 8px;
            border: none;
            outline: none;
            color: $text-additional;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            display: flex;
            align-items: center;
            letter-spacing: -0.22px;
          }
        }
      }

      .artists-list-container {
        flex: 1;
        min-height: 0;
        position: relative;
      }

      .artists-list {
        height: 100%;
        overflow-y: auto;
        scrollbar-width: thin;
        scrollbar-color: $accent rgba(#cdcccc, 0.3);
        padding-bottom: 20px;

        &::-webkit-scrollbar {
          width: 6px;
        }

        &::-webkit-scrollbar-track {
          @include background-opacity(#cdcccc, 0.3);
          border-radius: 5px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: $accent;
          border-radius: 5px;
        }

        .artist-card {
          display: flex;
          align-items: center;
          width: calc(100%);
          padding: 8px;
          background-color: #f6f6f6;
          border: 2px solid #f6f6f6;
          border-radius: 10px;
          margin-bottom: 20px;
          cursor: pointer;

          &.active,
          &:hover {
            border: 2px solid #838383;
            box-shadow: 0px 16px 24px rgba(59, 59, 59, 0.08);
          }

          &.active {
            background: #ffffff;
            border: 2px solid $accent;
          }

          .avatar {
            .picture {
              img {
                height: 57px;
                width: 57px;
                object-fit: cover;
                border-radius: 50%;
              }
            }

            .initials {
              display: flex;
              justify-content: center;
              align-items: center;
              height: 57px;
              width: 57px;
              font-family: sans-serif;
              font-size: 20px;
              background-color: $black;
              border: 1px solid $accent;
              color: $white;
              border-radius: 50%;
            }
          }

          .name {
            margin-left: 16px;
            flex: 1;
            color: $text;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 16px;
            letter-spacing: -0.2475px;
            margin-right: 4px;
          }
        }

        .scroll-to-load {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 30px 0;
          width: 100%;
          background: white;
          border-radius: 10px;
          border: 2px solid #f6f6f6;
          margin-top: 20px;

          .load-more-text {
            color: $text-additional;
            font-size: 14px;
            margin-bottom: 8px;
          }
        }
      }
    }

    .right-panel-artist {
      height: 100%;
      background: white;
      border-radius: 10px;
      border: 1px solid #e2e2e2;
      max-height: calc(100vh - 90px);
    }
  }

  .add-collaborator {
    width: 100%;
    display: flex;
    justify-content: center;

    button {
      display: flex;
      padding: 0.75rem 2.5rem;

      img {
        margin-right: 0.5rem;
      }
    }
  }

  .cards {
    width: 100%;
    margin-bottom: 20px;
    // border: 2px solid #f6f6f6;
    // border-radius: 10px;
    padding: 10px;

    &.pending {
      border-top: 2px solid #f6f6f6;
    }
  }
}
</style>
