<template>
  <div class="right-panel">
    <div v-if="!showPanel" />
    <div v-else class="panel-container">
      <div class="user-header">
        <div class="avatar" v-if="artist.avatar_url">
          <img :src="artist.avatar_url" />
        </div>
        <div class="initials" v-else>
          {{ artist.name | initials }}
        </div>
        <div class="user-details">
          <div class="name">
            <h1>{{ artist.name }}</h1>
          </div>
          <div class="ipi">
            <div class="publisher-ipi">
              <p class="title">Publisher IPI</p>
              <p class="val">{{ artist.ipi_publisher || "-" }}</p>
              <p class="val2">{{ artist.publisher_name }}</p>
            </div>
            <div class="songwriter-ipi">
              <p class="title">Songwriter IPI</p>
              <p class="val">{{ artist.ipi_songwriter || "-" }}</p>
            </div>
          </div>
          <div class="actions">
            <button
              class="primary"
              v-if="!viewingMyArtist && !artist.pending"
              @click="showModal = true"
            >
              Create Deal
            </button>
            <div class="socials opacity-0 pointer-events-none">
              <img src="../assets/icons/spotify.svg" />
              <img src="../assets/icons/itunes-color.svg" />
              <img src="../assets/icons/youtube.svg" />
              <img src="../assets/icons/instagram-color.svg" />
              <img src="../assets/icons/twitter.svg" />
            </div>
          </div>
        </div>
      </div>
      <div v-if="artist.pending" class="pending-invite">
        <div class="invite-content">
          <div class="invite-icon">
            <svg
              width="49"
              height="50"
              viewBox="0 0 49 50"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_5232_6149)">
                <path
                  d="M47.8346 1.67188L22.168 27.3385"
                  stroke="#666666"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M47.8346 1.67188L31.5013 48.3385L22.168 27.3385L1.16797 18.0052L47.8346 1.67188Z"
                  stroke="#666666"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_5232_6149">
                  <rect width="49" height="50" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
          <h2>Invite Pending</h2>
          <p class="invite-text">
            {{ artist.name }} is not invited yet. Share the invite link to start
            collaborating.
          </p>
          <div class="invite-link">
            <input
              type="text"
              readonly
              :value="`nvoko.com/invite/${artist.token}`"
              class="link-input"
            />
            <button
              class="primary"
              @click="
                copyInviteLink(`https://nvoko.com/invite/${artist.token}`)
              "
            >
              {{ inviteLinkCopied ? "Copied!" : "Copy Invite link" }}
            </button>
          </div>
          <button class="text-button" @click="resendInvite">
            Resend Invite
          </button>
        </div>
      </div>
      <div v-else class="history-container">
        <Loader
          color="#FF035A"
          size="40"
          v-if="$apollo.queries.history.loading"
        />
        <div v-else-if="history" class="history">
          <p class="title">
            <span>You </span> and <span>{{ artist.name }}</span>
          </p>
          <div class="history-item" v-if="!history.length">
            <span class="description">No Deals Yet.</span>
          </div>
          <div v-else>
            <div
              v-for="{ id, song_id, time, text, song } in history"
              :key="id"
              @click="navigateSong(song_id)"
              class="flex items-center p-4 mb-2 transition-colors duration-200 bg-white rounded-lg cursor-pointer hover:bg-gray-50"
            >
              <img
                :src="song.album.images[0]"
                :alt="song.name"
                class="object-cover w-16 h-16 rounded-lg"
              />
              <div class="flex-1 ml-4">
                <h3 class="text-lg font-medium text-gray-900">
                  {{ song.name }}
                </h3>
                <div class="flex items-center justify-start">
                  <div
                    v-if="getTrackStatus(song) == 'protected'"
                    class="flex items-center justify-center gap-1 px-3 py-2 text-xs font-semibold rounded-lg cursor-default select-none"
                    style="background-color: #F5FFE4; color: #7AA62D;"
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15.8333 9.16797H4.16667C3.24619 9.16797 2.5 9.91416 2.5 10.8346V16.668C2.5 17.5884 3.24619 18.3346 4.16667 18.3346H15.8333C16.7538 18.3346 17.5 17.5884 17.5 16.668V10.8346C17.5 9.91416 16.7538 9.16797 15.8333 9.16797Z"
                        stroke="#7AA62D"
                        stroke-width="1.66667"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M5.83203 9.16797V5.83464C5.83203 4.72957 6.27102 3.66976 7.05242 2.88836C7.83382 2.10696 8.89363 1.66797 9.9987 1.66797C11.1038 1.66797 12.1636 2.10696 12.945 2.88836C13.7264 3.66976 14.1654 4.72957 14.1654 5.83464V9.16797"
                        stroke="#7AA62D"
                        stroke-width="1.66667"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>

                    Protected
                  </div>
                  <div
                    v-if="getTrackStatus(song) == 'waiting'"
                    class="flex items-center justify-center gap-1 px-3 py-2 text-xs font-semibold rounded-lg cursor-default select-none"
                    style="background-color: #FFF0E0; color: #FF8C00;"
                  >
                    <svg
                      width="14"
                      height="18"
                      viewBox="0 0 14 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.4 1.2C0.4 0.615625 0.88125 0.0999997 1.5 0.0999997H2.6H11.4H12.5C13.0844 0.0999997 13.6 0.615625 13.6 1.2C13.6 1.81875 13.0844 2.3 12.5 2.3V2.67812C12.5 4.15625 11.9156 5.56562 10.8844 6.59687L8.54687 8.9L10.8844 11.2375C11.9156 12.2687 12.5 13.6781 12.5 15.1219V15.5C13.0844 15.5 13.6 16.0156 13.6 16.6C13.6 17.2188 13.0844 17.7 12.5 17.7H11.4H2.6H1.5C0.88125 17.7 0.4 17.2188 0.4 16.6C0.4 16.0156 0.88125 15.5 1.5 15.5V15.1219C1.5 13.6781 2.05 12.2687 3.08125 11.2375L5.41875 8.9L3.08125 6.59687C2.05 5.56562 1.5 4.15625 1.5 2.67812V2.3C0.88125 2.3 0.4 1.81875 0.4 1.2ZM3.7 2.3V2.67812C3.7 3.57187 4.04375 4.39687 4.6625 5.01562L7 7.35312L9.30312 5.01562C9.92187 4.39687 10.3 3.57187 10.3 2.67812V2.3H3.7ZM3.7 15.5H10.3V15.1219C10.3 14.2625 9.92187 13.4375 9.30312 12.8187L7 10.4812L4.6625 12.8187C4.04375 13.4375 3.7 14.2625 3.7 15.1562V15.5344V15.5Z"
                        fill="#FF8C00"
                      />
                    </svg>

                    Waiting
                  </div>
                  <div
                    v-else-if="getTrackStatus(song) == 'action-needed'"
                    class="flex items-center justify-center gap-1 px-3 py-2 text-xs font-semibold rounded-lg cursor-default select-none"
                    style="background-color: #FFEAE8; color: #D35A4D;"
                  >
                    <svg
                      width="22"
                      height="19"
                      viewBox="0 0 22 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M20.6594 15.7594C21.2781 16.8594 20.4875 18.2 19.2156 18.2H2.75C1.47812 18.2 0.687499 16.825 1.30625 15.7594L9.55625 1.425C10.175 0.325 11.7906 0.359375 12.4094 1.425L20.6594 15.7594ZM11 12.7687C10.1062 12.7687 9.41875 13.4906 9.41875 14.35C9.41875 15.2437 10.1062 15.9312 11 15.9312C11.8594 15.9312 12.5812 15.2437 12.5812 14.35C12.5812 13.4906 11.8594 12.7687 11 12.7687ZM9.4875 7.09687L9.72812 11.7719C9.7625 12.0125 9.93437 12.15 10.1406 12.15H11.825C12.0312 12.15 12.2031 12.0125 12.2375 11.7719L12.4781 7.09687C12.5125 6.85625 12.3062 6.65 12.0656 6.65H9.9C9.65937 6.65 9.45312 6.85625 9.4875 7.09687Z"
                        fill="#A62D2D"
                      />
                    </svg>

                    Action Needed
                  </div>
                </div>
              </div>
              <span class="text-sm text-gray-500">{{ time }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <AddSong
      v-if="showModal"
      @close="showModal = false"
      :artist="artist"
      :my-artist="myArtist"
    />
  </div>
</template>

<script>
import Loader from "@/components/Loader.vue";
import AddSong from "@/components/modals/AddSong.vue";

import GET_DEALS_HISTORY from "@/api/queries/GET_DEALS_HISTORY.gql";

export default {
  name: "ContactPreview",
  components: {
    Loader,
    AddSong
  },
  props: {
    artists: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      showModal: false,
      inviteLinkCopied: false
    };
  },
  computed: {
    myArtist() {
      return this.$store.getters["account/getArtist"];
    },
    viewingMyArtist() {
      return this.artist.id === this.myArtist.id;
    },
    artist() {
      const { artist } = this.$route.query;
      if (!artist) return {};
      return this.artists.find(el => el.id === artist) || {};
    },
    safeArtist() {
      return this.artist || {};
    },
    showPanel() {
      return Object.keys(this.safeArtist).length > 0;
    }
  },
  filters: {
    initials(name) {
      return name
        ?.split(" ")
        .slice(0, 2)
        .map(el => el.charAt(0).toUpperCase())
        .join("");
    }
  },
  methods: {
    getTrackStatus() {
      return "waiting";
    },
    formatHistory(deals) {
      return deals.map(
        ({ id, song_id, created_at, initiator, from, to, song }) => {
          console.log("song", song);
          return {
            id,
            song_id,
            time: this.getHistoryTime(created_at),
            text: this.getHistoryText(initiator, from, to),
            song: song.imported_track
          };
        }
      );
    },
    getHistoryTime(timestamp) {
      const created_at = new Date(timestamp);
      const now = new Date();
      const diff = new Date(now - created_at);
      const days = diff.getUTCDate() - 1;
      const hours = diff.getUTCHours();
      if (days) return `${days} day${days > 1 ? "s" : ""} ago`;
      else return `${hours} hour${hours > 1 ? "s" : ""} ago`;
    },
    getHistoryText(initiator, from, to) {
      const isInitiator = this.myArtist.id === initiator;
      return isInitiator
        ? `You have requested a feature from ${to.name ||
            to.artist_users[0].user.email}.`
        : `${from.name ||
            to.artist_users[0].user.email} has requested a feature from you.`;
    },
    navigateSong(id) {
      this.$router.push({ name: "Catalog", query: { song: id } });
    },
    copyInviteLink(link) {
      navigator.clipboard.writeText(link).then(() => {
        this.inviteLinkCopied = true;
        setTimeout(() => {
          this.inviteLinkCopied = false;
        }, 2000);
      });
    },
    resendInvite() {
      // TODO: Implement resend invite functionality
      console.log("Resending invite...");
    }
  },
  apollo: {
    history: {
      query: GET_DEALS_HISTORY,
      fetchPolicy: "network-only",

      variables() {
        return {
          myArtistId: this.myArtist.id,
          artistId: this.artist.id
        };
      },
      update({ deals }) {
        return this.formatHistory(deals);
      },
      skip() {
        return !this.showPanel;
      },
      error(e) {
        // this.$apollo.queries.history.refetch();
        // window.location.reload();
        // Clear the cache for this query
        this.$apollo.getClient().resetStore();

        console.log("error", e);
      }
    }
  },
  mounted() {
    console.log("artist", this.artist);
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/_mixins.scss";

.right-panel {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  background: white;
  border-radius: 10px;
  .panel-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 60px 40px;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: $accent rgba(#cdcccc, 0.3);

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      @include background-opacity(#cdcccc, 0.3);
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $accent;
      border-radius: 5px;
    }

    .user-header {
      display: flex;
      justify-content: center;
      align-items: center;

      .avatar {
        img {
          width: 10rem;
          max-height: 10rem;
          object-fit: cover;
          border-radius: 50%;
          filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.0001));
        }
      }

      .initials {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 10rem;
        width: 10rem;
        font-family: sans-serif;
        font-size: 70px;
        background-color: $black;
        border: 1px solid $accent;
        color: $white;
        border-radius: 50%;
      }

      .user-details {
        margin-left: 3.5rem;

        .name {
          display: flex;

          h1 {
            font-family: Helvetica;
            font-style: normal;
            font-weight: normal;
            font-size: 2.5rem;
            line-height: 46px;
            letter-spacing: -0.666667px;
            color: $text;
          }
        }

        .ipi {
          display: flex;
          margin-top: 2rem;

          .title {
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.22px;
            color: $text;
          }

          .val {
            margin-top: 5px;
            font-size: 24px;
            line-height: 28px;
            letter-spacing: 1px;
            color: $accent;
          }

          .val2 {
            margin-top: 5px;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: -0.22px;
            color: $text;
          }

          .songwriter-ipi {
            margin-left: 85px;
          }
        }

        .actions {
          display: flex;
          align-items: center;
          margin-top: 3rem;

          .socials {
            display: flex;
            margin-left: 1.5rem;

            img {
              height: 23px;
              margin-right: 16px;
              cursor: pointer;
              -webkit-filter: grayscale(100%);
              filter: grayscale(100%);

              &:hover {
                -webkit-filter: grayscale(0%);
                filter: grayscale(0%);
              }
            }
          }
        }
      }
    }

    .history-container {
      flex: 1;

      .history {
        margin-top: 5rem;
        display: flex;
        flex-direction: column;

        .title {
          @include category-medium();
          color: $text;
          margin-bottom: 16px;

          span {
            font-weight: bold;
          }
        }

        .history-item {
          display: flex;
          align-items: center;
          margin-bottom: 16px;
          padding: 13px 24px;
          border-radius: 6px;
          background: $gray;

          .time {
            @include date-time-light();
            color: $text;
            width: 5rem;
          }

          .description {
            @include body-light();
            color: $text;
            flex: 1;
            margin-left: 20px;
          }
        }

        .view-all {
          display: flex;
          justify-content: center;

          a {
            text-align: center;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: -0.22px;
            text-decoration-line: underline;
            color: $text;
          }
        }
      }
    }
  }
}

.pending-invite {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  margin-top: 2rem;
  border-radius: 10px;
  border: 1px solid #66666655;

  .invite-content {
    text-align: center;
    max-width: 700px;

    .invite-icon {
      margin-bottom: 1.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    h2 {
      font-size: 1.5rem;
      font-weight: 600;
      color: $text;
      margin-bottom: 1rem;
    }

    .invite-text {
      color: $text-additional;
      font-size: 1rem;
      line-height: 1.5;
      margin-bottom: 2rem;
    }

    .invite-link {
      display: flex;
      gap: 1rem;
      margin-bottom: 1.5rem;

      .link-input {
        flex: 1;
        padding: 0.75rem 1rem;
        border: 1px solid #e2e2e2;
        border-radius: 6px;
        font-size: 0.875rem;
        color: $text-additional;
        background: #f6f6f6;
      }

      button {
        white-space: nowrap;
      }
    }

    .text-button {
      color: $accent;
      font-size: 0.875rem;
      text-decoration: underline;
      background: none;
      border: none;
      cursor: pointer;

      &:hover {
        color: darken($accent, 10%);
      }
    }
  }
}
</style>
