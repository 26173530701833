import axios from "axios";
import store from "@/store";

const S3_UPLOAD_URL = "https://hasura-remote.nvoko.com/api/s3/upload";

export const uploadFile = async (file, folder, onProgress) => {
  const formData = new FormData();
  formData.append("folder", folder);
  formData.append("file", file);

  const token = store.getters["account/getApolloToken"];

  try {
    const response = await axios({
      method: "post",
      url: S3_UPLOAD_URL,
      data: formData,
      headers: {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "POST",
        "Access-Control-Allow-Headers": "Content-Type, Authorization"
      },
      onUploadProgress: progressEvent => {
        if (onProgress) {
          const progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          onProgress(progress);
        }
      }
    });

    return response.data;
  } catch (error) {
    if (error.response?.status === 401) {
      window.location.href = "/login";
    }
    throw error;
  }
};

export const uploadFiles = async (files, folder, onProgress) => {
  const formData = new FormData();
  formData.append("folder", folder);

  files.forEach(file => {
    formData.append("file", file);
  });

  const token = store.getters["account/getApolloToken"];

  try {
    const response = await axios({
      method: "post",
      url: S3_UPLOAD_URL,
      data: formData,
      headers: {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "POST",
        "Access-Control-Allow-Headers": "Content-Type, Authorization"
      },
      onUploadProgress: progressEvent => {
        if (onProgress) {
          const progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          onProgress(progress);
        }
      }
    });

    return response.data;
  } catch (error) {
    if (error.response?.status === 401) {
      window.location.href = "/login";
    }
    throw error;
  }
};
