<template>
  <div class="right-panel">
    <div v-if="!Object.keys(song || {}).length" />
    <div v-else class="panel-container-outer">
      <div class="panel-header pb-4 border-b border-gray-200">
        <span class="flex items-center justify-start gap-3">
          <span class="flex items-center justify-between w-full gap-1">
            <span
              class="flex items-baseline justify-start flex-1 gap-1"
              v-if="!isEditingName"
              @dblclick="
                () => (song.imported_track_id ? null : editSongName(song.id))
              "
            >
              {{ song.name }}

              <span class="flex items-baseline gap-0.5">
                <svg
                  v-if="hasPlatform('spotify')"
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-brand-spotify"
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                  <path d="M8 11.973c2.5 -1.473 5.5 -.973 7.5 .527" />
                  <path d="M9 15c1.5 -1 4 -1 5 .5" />
                  <path d="M7 9c2 -1 6 -2 10 .5" />
                </svg>
                <svg
                  v-if="hasPlatform('apple music')"
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-brand-apple"
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path
                    d="M8.286 7.008c-3.216 0 -4.286 3.23 -4.286 5.92c0 3.229 2.143 8.072 4.286 8.072c1.165 -.05 1.799 -.538 3.214 -.538c1.406 0 1.607 .538 3.214 .538s4.286 -3.229 4.286 -5.381c-.03 -.011 -2.649 -.434 -2.679 -3.23c-.02 -2.335 2.589 -3.179 2.679 -3.228c-1.096 -1.606 -3.162 -2.113 -3.75 -2.153c-1.535 -.12 -3.032 1.077 -3.75 1.077c-.729 0 -2.036 -1.077 -3.214 -1.077z"
                  />
                  <path d="M12 4a2 2 0 0 0 2 -2a2 2 0 0 0 -2 2" />
                </svg>
                <svg
                  v-if="hasPlatform('deezer')"
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-brand-deezer"
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M3 16.5h2v.5h-2z" />
                  <path d="M8 16.5h2.5v.5h-2.5z" />
                  <path d="M16 17h-2.5v-.5h2.5z" />
                  <path d="M21.5 17h-2.5v-.5h2.5z" />
                  <path d="M21.5 13h-2.5v.5h2.5z" />
                  <path d="M21.5 9.5h-2.5v.5h2.5z" />
                  <path d="M21.5 6h-2.5v.5h2.5z" />
                  <path d="M16 13h-2.5v.5h2.5z" />
                  <path d="M8 13.5h2.5v-.5h-2.5z" />
                  <path d="M8 9.5h2.5v.5h-2.5z" />
                </svg>
              </span>
            </span>
            <input
              class="flex-1"
              v-else
              v-model="tempSongName"
              @blur="saveSongName"
              @keyup.enter="saveSongName"
            />

            <!-- <button
              v-if="!song.imported_track_id"
              class="p-2"
              @click="isEditingName ? saveSongName() : editSongName()"
            >
              <svg
                v-if="!isEditingName"
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.8289 5.32398C17.8219 5.33101 17.815 5.33816 17.8083 5.34543L17.6812 5.48235L15.0193 2.8203L15.1539 2.69802C15.1637 2.68912 15.1733 2.67998 15.1826 2.67062C15.5016 2.35192 16.0187 2.35171 16.338 2.67038C16.338 2.67042 16.3381 2.67046 16.3381 2.6705C16.3382 2.67057 16.3382 2.67063 16.3383 2.67069L17.8315 4.16403C17.832 4.16453 17.8325 4.16504 17.833 4.16554C18.1494 4.48746 18.1477 5.00436 17.8289 5.32398ZM17.1142 7.27239C17.1676 7.32579 17.2275 7.37118 17.292 7.40781L7.2989 17.4018L0.998983 19.5037L2.93429 13.6834L6.64006 17.358L7.81359 16.1746L4.0973 12.4895L2.94482 13.6518L3.09442 13.2019L13.091 3.2096C13.1269 3.27148 13.171 3.32901 13.2225 3.38051L17.1142 7.27239ZM7.64167 17.3326C7.63781 17.3326 7.63395 17.3326 7.63009 17.3327L7.64167 17.3326ZM7.34514 17.3746C7.34208 17.3769 7.33888 17.3792 7.33555 17.3817C7.33144 17.3846 7.3275 17.3875 7.32374 17.3902C7.33218 17.3838 7.33941 17.3786 7.34514 17.3746ZM7.29021 17.4173C7.28694 17.42 7.28366 17.4227 7.28036 17.4254C7.28299 17.4232 7.28557 17.4209 7.28811 17.4188C7.28879 17.4183 7.28949 17.4178 7.29021 17.4173ZM0.746687 19.7471L0.746472 19.7469L0.746687 19.7471ZM3.15351 13.1107C3.1539 13.1101 3.15292 13.1117 3.15103 13.1148L3.15245 13.1125L3.15323 13.1112L3.15343 13.1109L3.15349 13.1108L3.15351 13.1107Z"
                  stroke="#111111"
                  stroke-width="1.66667"
                  stroke-linejoin="round"
                />
              </svg>

              <svg
                v-else
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-check"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="#000000"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M5 12l5 5l10 -10" />
              </svg>
            </button> -->
            <button
              class="inline-flex items-center gap-2 px-1 py-1 mt-2 text-xs font-medium cursor-pointer"
              @click="$router.push('/catalog')"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="w-5 h-5"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="#000000"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M18 6l-12 12" />
                <path d="M6 6l12 12" />
              </svg>
            </button>
          </span>

          <span
            v-if="song.purchased"
            @click="openPaymentDropin"
            class="inline-flex items-center gap-2 px-2 py-1 mt-2 text-xs font-medium text-green-600 rounded-md cursor-pointer bg-green-50 ring-1 ring-inset ring-green-200"
          >
            <svg
              class="w-5 h-5 text-green-400"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                clip-rule="evenodd"
              ></path>
            </svg>
            Paid
          </span>

          <span
            v-if="
              song.payment_done_off_platform == null &&
                songStatus == 'contract-ready'
            "
            @click="openPaymentDoneOffPlatformModal"
            class="inline-flex items-center gap-2 px-2 py-1 mt-2 text-xs font-medium text-yellow-600 rounded-md cursor-pointer bg-yellow-50 ring-1 ring-inset ring-yellow-200"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="text-yellow-400"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path
                d="M14.897 1a4 4 0 0 1 2.664 1.016l.165 .156l4.1 4.1a4 4 0 0 1 1.168 2.605l.006 .227v5.794a4 4 0 0 1 -1.016 2.664l-.156 .165l-4.1 4.1a4 4 0 0 1 -2.603 1.168l-.227 .006h-5.795a3.999 3.999 0 0 1 -2.664 -1.017l-.165 -.156l-4.1 -4.1a4 4 0 0 1 -1.168 -2.604l-.006 -.227v-5.794a4 4 0 0 1 1.016 -2.664l.156 -.165l4.1 -4.1a4 4 0 0 1 2.605 -1.168l.227 -.006h5.793zm-2.897 14a1 1 0 0 0 -.993 .883l-.007 .117l.007 .127a1 1 0 0 0 1.986 0l.007 -.117l-.007 -.127a1 1 0 0 0 -.993 -.883zm1.368 -6.673a2.98 2.98 0 0 0 -3.631 .728a1 1 0 0 0 1.44 1.383l.171 -.18a.98 .98 0 0 1 1.11 -.15a1 1 0 0 1 -.34 1.886l-.232 .012a1 1 0 0 0 .111 1.994a3 3 0 0 0 1.371 -5.673z"
                stroke-width="0"
                fill="currentColor"
              />
            </svg>

            Was the payment done off platform?
          </span>

          <span
            v-if="song.payment_done_off_platform === true"
            class="inline-flex items-center gap-2 px-2 py-1 mt-2 text-xs font-medium rounded-md cursor-default text-nvoko bg-red-50 ring-1 ring-inset ring-red-200"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="text-red-400"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path
                d="M14.897 1a4 4 0 0 1 2.664 1.016l.165 .156l4.1 4.1a4 4 0 0 1 1.168 2.605l.006 .227v5.794a4 4 0 0 1 -1.016 2.664l-.156 .165l-4.1 4.1a4 4 0 0 1 -2.603 1.168l-.227 .006h-5.795a3.999 3.999 0 0 1 -2.664 -1.017l-.165 -.156l-4.1 -4.1a4 4 0 0 1 -1.168 -2.604l-.006 -.227v-5.794a4 4 0 0 1 1.016 -2.664l.156 -.165l4.1 -4.1a4 4 0 0 1 2.605 -1.168l.227 -.006h5.793zm-2.897 14a1 1 0 0 0 -.993 .883l-.007 .117l.007 .127a1 1 0 0 0 1.986 0l.007 -.117l-.007 -.127a1 1 0 0 0 -.993 -.883zm1.368 -6.673a2.98 2.98 0 0 0 -3.631 .728a1 1 0 0 0 1.44 1.383l.171 -.18a.98 .98 0 0 1 1.11 -.15a1 1 0 0 1 -.34 1.886l-.232 .012a1 1 0 0 0 .111 1.994a3 3 0 0 0 1.371 -5.673z"
                stroke-width="0"
                fill="currentColor"
              />
            </svg>

            Payment not secured by Nvoko
          </span>

          <span
            v-if="song.payment_done_off_platform === false"
            class="inline-flex items-center gap-2 px-2 py-1 mt-2 text-xs font-medium text-green-600 rounded-md cursor-default bg-green-50 ring-1 ring-inset ring-green-200"
          >
            <svg
              class="w-5 h-5 text-green-400"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                clip-rule="evenodd"
              ></path>
            </svg>

            Payment secured by Nvoko
          </span>
        </span>
      </div>
      <div class="panel-container ">
        <div class="flex items-center justify-start w-full py-4 ">
          <button class="primary outline" @click="openConnectToRelease">
            Connect to Release
          </button>
        </div>

        <div class="deal-status" style="padding: 0 !important;">
          <div
            v-if="
              !song.purchased &&
                !(dealStatus == 'pending' || dealStatus == 'not-started')
            "
            class="item"
          >
            <div class="flex items-center w-full gap-3">
              <div class="flex-shrink-0">
                <svg
                  class="w-5 h-5 mt-0.5 text-nvoko animate-pulse"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </div>
              <p
                class="flex items-center justify-between flex-1 text-sm font-bold text-nvoko "
              >
                Contract hasn't been paid yet.
                <button
                  :disabled="song.collaborators.length === 1"
                  class="primary"
                  @click="openPaymentDropin"
                >
                  Pay
                </button>
              </p>
            </div>
          </div>
          <!-- <div v-if="song.payment_done_off_platform == null" class="item">
          <div class="flex items-center w-full gap-3">
            <div class="flex-shrink-0">
              <svg
                class="w-5 h-5 mt-0.5 text-nvoko animate-pulse"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </div>
            <p
              class="flex items-center justify-between flex-1 text-sm font-bold text-nvoko "
            >
              Payment status undetermined.

              <button class="primary" @click="openPaymentDoneOffPlatformModal">
                Set status
              </button>
            </p>
          </div>
        </div> -->
        </div>
        <div class="collaborators">
          <!-- <div
          class="card company"
          v-if="companyOptions.applicable && song.collaborators.length > 1"
        >
          <template v-if="loading">
            <div class="flex items-center justify-center w-full h-full">
              <div class="spinner" />
            </div>
          </template>

<template v-else>
            <div class="step">
              <span class="question">
                Who would be entering into this agreement?
              </span>
              <div class="inputs">
                <div class="radiobuttons">
                  <div
                    class="radio-group"
                    v-for="(choice, i) in companyQuestion.choices"
                    :key="i"
                  >
                    <label class="radio">
                      <input
                        type="radio"
                        :value="choice"
                        v-model="companyQuestion.answer"
                      />
                      <span class="text">
                        {{ choice.label }}
                        <template v-if="choice.value">
                          <br />
                          + <br />
                          {{ artist.name }}
                        </template>
</span>
<span class="checkmark"></span>
</label>
</div>
</div>
</div>
</div>
<button class="primary outline" :disabled="submitting === song.id" @click="updateCompanyData">
  {{ submitting ? "Submitting" : "Continue" }}
</button>
</template>
</div> -->
          <template>
            <div
              class="card"
              v-for="({
                info,
                services,
                percentage = {},
                isMe,
                songHost,
                hostActive,
                status,
                deal_id,
                fee_status,
                fee,
                inducement,
                category,
                recoupableFee
                // chargesEnabled,
                // fee
              },
              i) in song.collaborators"
              :key="i"
            >
              <div class="top">
                <div class="artist">
                  <img
                    class="object-cover w-full h-full"
                    v-if="info.avatar_url"
                    :src="info.avatar_url"
                  />
                  <div v-else class="initials">
                    {{ info.name | initials }}
                  </div>
                  <div class="details">
                    <span class="name">{{ info.name }}</span>
                    <span class="contribution" v-if="!songHost">
                      {{ services | contribution }}
                    </span>
                  </div>
                </div>
                <div class="party-icon" v-if="!i">Host</div>
                <div
                  class="my-auto"
                  v-else-if="
                    category === 'completed' ||
                      song.uploaded_contracts?.length > 0
                  "
                >
                  <div
                    class="flex items-center justify-center gap-1 px-2 py-2 text-xs font-semibold rounded-lg cursor-default select-none"
                    style="background-color: #F5FFE4; color: #7AA62D;"
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15.8333 9.16797H4.16667C3.24619 9.16797 2.5 9.91416 2.5 10.8346V16.668C2.5 17.5884 3.24619 18.3346 4.16667 18.3346H15.8333C16.7538 18.3346 17.5 17.5884 17.5 16.668V10.8346C17.5 9.91416 16.7538 9.16797 15.8333 9.16797Z"
                        stroke="#7AA62D"
                        stroke-width="1.66667"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M5.83203 9.16797V5.83464C5.83203 4.72957 6.27102 3.66976 7.05242 2.88836C7.83382 2.10696 8.89363 1.66797 9.9987 1.66797C11.1038 1.66797 12.1636 2.10696 12.945 2.88836C13.7264 3.66976 14.1654 4.72957 14.1654 5.83464V9.16797"
                        stroke="#7AA62D"
                        stroke-width="1.66667"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </div>
                <div class="attention" v-else-if="category === 'action-needed'">
                  <svg
                    width="44"
                    height="32"
                    viewBox="0 0 44 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="44" height="32" rx="8" fill="#FFE4E4" />
                    <path
                      d="M31.6594 21.7594C32.2781 22.8594 31.4875 24.2 30.2156 24.2H13.75C12.4781 24.2 11.6875 22.825 12.3062 21.7594L20.5562 7.425C21.175 6.325 22.7906 6.35937 23.4094 7.425L31.6594 21.7594ZM22 18.7687C21.1062 18.7687 20.4187 19.4906 20.4187 20.35C20.4187 21.2437 21.1062 21.9312 22 21.9312C22.8594 21.9312 23.5812 21.2437 23.5812 20.35C23.5812 19.4906 22.8594 18.7687 22 18.7687ZM20.4875 13.0969L20.7281 17.7719C20.7625 18.0125 20.9344 18.15 21.1406 18.15H22.825C23.0312 18.15 23.2031 18.0125 23.2375 17.7719L23.4781 13.0969C23.5125 12.8562 23.3062 12.65 23.0656 12.65H20.9C20.6594 12.65 20.4531 12.8562 20.4875 13.0969Z"
                      fill="#A62D2D"
                    />
                  </svg>
                </div>
                <div class="attention" v-else-if="category === 'pending'">
                  <svg
                    width="44"
                    height="32"
                    viewBox="0 0 44 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="44" height="32" rx="8" fill="#FFF0E0" />
                    <path
                      d="M15.4 8.2C15.4 7.61562 15.8812 7.1 16.5 7.1H17.6H26.4H27.5C28.0844 7.1 28.6 7.61562 28.6 8.2C28.6 8.81875 28.0844 9.3 27.5 9.3V9.67812C27.5 11.1562 26.9156 12.5656 25.8844 13.5969L23.5469 15.9L25.8844 18.2375C26.9156 19.2687 27.5 20.6781 27.5 22.1219V22.5C28.0844 22.5 28.6 23.0156 28.6 23.6C28.6 24.2188 28.0844 24.7 27.5 24.7H26.4H17.6H16.5C15.8812 24.7 15.4 24.2188 15.4 23.6C15.4 23.0156 15.8812 22.5 16.5 22.5V22.1219C16.5 20.6781 17.05 19.2687 18.0812 18.2375L20.4187 15.9L18.0812 13.5969C17.05 12.5656 16.5 11.1562 16.5 9.67812V9.3C15.8812 9.3 15.4 8.81875 15.4 8.2ZM18.7 9.3V9.67812C18.7 10.5719 19.0437 11.3969 19.6625 12.0156L22 14.3531L24.3031 12.0156C24.9219 11.3969 25.3 10.5719 25.3 9.67812V9.3H18.7ZM18.7 22.5H25.3V22.1219C25.3 21.2625 24.9219 20.4375 24.3031 19.8187L22 17.4812L19.6625 19.8187C19.0437 20.4375 18.7 21.2625 18.7 22.1562V22.5344V22.5Z"
                      fill="#FF8C00"
                    />
                  </svg>
                </div>
              </div>
              <template v-if="isMe || song.isHost">
                <div class="split-name">Royalties and Payments</div>
                <div class="split">
                  <div>
                    <span class="type">Recording</span>
                    <span
                      class="percentage"
                      :class="{
                        invalid:
                          percentage.recording > 100 || percentage.recording < 0
                      }"
                    >
                      {{ percentage.recording + "%" }}
                    </span>
                  </div>
                  <div>
                    <span class="type">Publishing</span>
                    <span
                      class="percentage"
                      :class="{
                        invalid:
                          percentage.publishing > 100 ||
                          percentage.publishing < 0
                      }"
                    >
                      {{ percentage.publishing + "%" }}
                    </span>
                  </div>
                  <div v-if="!songHost">
                    <span class="type">Guest Fee</span>
                    <span class="percentage">
                      {{ fee | currency }}
                    </span>
                  </div>
                  <div v-if="!songHost">
                    <span class="type">Recoupable</span>
                    <span class="percentage">
                      {{ recoupableFee | currency }}
                    </span>
                  </div>
                </div>
              </template>
              <div
                class="deal-status"
                v-if="
                  (isMe || song.isHost) && !songHost && status !== 'pending'
                "
              >
                <div
                  class="item"
                  style="background-color: rgb(252, 238, 242, 0);"
                >
                  <!-- <span class="type">Deal Terms</span>
                <span
                  class="flex items-center justify-start gap-2 status"
                  v-if="status === 'contract-ready'"
                >
                  Completed

                  <Icon name="check" color="#e3507a" />
                </span>
                <span class="status" v-else-if="!song.purchased">
                  Purchase Pending
                </span>
                <span class="status" v-else>
                  Pending | Waiting on
                  <b>
                    {{
                      ["contract-signed-host", "deal-updated-host"].includes(
                        status
                      )
                        ? song.isHost
                          ? info.name
                          : "You"
                        : song.isHost
                        ? "You"
                        : song.collaborators[0].info.name
                    }}
                  </b>
                  to sign
                </span> -->
                  <button
                    class="primary outline"
                    @click="
                      openModal('review', deal_id, status, info, hostActive)
                    "
                    v-if="
                      (isMe || song.isHost) &&
                        (status.includes('deal-updated-') ||
                          status.includes('contract-signed-'))
                    "
                  >
                    Review Terms
                  </button>
                  <button
                    class="primary"
                    @click="
                      openModal('view-terms', deal_id, status, info, hostActive)
                    "
                    v-else
                  >
                    View Contract
                  </button>
                </div>
                <div class="item" v-if="song.purchased">
                  <span class="type">Main Agreement</span>
                  <span
                    class="flex items-center justify-start gap-2 status"
                    v-if="status === 'contract-ready'"
                  >
                    Completed
                    <Icon name="check" color="#e3507a" />
                  </span>
                  <span class="status" v-else>
                    <!-- Waiting on
                  <b>
                    {{
      ["contract-signed-host", "deal-updated-host"].includes(
        status
      )
        ? song.isHost
          ? info.name
          : "You"
        : song.isHost
          ? "You"
          : song.collaborators[0].info.name
    }}
                  </b>
                  to sign -->
                    {{
                      ["contract-signed-host", "deal-updated-host"].includes(
                        status
                      )
                        ? song.isHost
                          ? "Preview contract"
                          : "Sign now"
                        : song.isHost
                        ? "Sign now"
                        : "Preview contract"
                    }}
                  </span>
                  <button
                    class="primary"
                    :class="{
                      outline:
                        (isMe || song.isHost) && status === 'contract-ready'
                    }"
                    @click="
                      openModal('view', deal_id, status, info, hostActive)
                    "
                    :disabled="!isMe && !song.isHost"
                  >
                    View
                  </button>
                </div>
                <div
                  class="item"
                  v-if="
                    status === 'contract-ready' &&
                      inducement &&
                      inducement.applicable
                  "
                >
                  <span class="type">
                    {{
                      song.isHost ? song.collaborators[0].info.name : info.name
                    }}'s Inducement Agreement
                  </span>
                  <span class="status" v-html="inducement.status"></span>
                  <button
                    class="primary"
                    @click="
                      openModal(
                        'inducement',
                        deal_id,
                        status,
                        info,
                        hostActive,
                        inducement
                      )
                    "
                    v-if="status === 'contract-ready' && inducement.canSign"
                  >
                    Sign Inducement Contract
                  </button>
                  <button
                    class="primary outline"
                    @click="
                      openModal(
                        'inducement',
                        deal_id,
                        status,
                        info,
                        hostActive,
                        inducement
                      )
                    "
                    v-if="status === 'contract-ready' && inducement.signature"
                  >
                    View Inducement Contract
                  </button>
                </div>
              </div>
              <div class="mt-3 buttons" v-if="!songHost">
                <template v-if="status === 'pending' && (isMe || song.isHost)">
                  <button
                    class="primary outline"
                    @click="
                      openModal(
                        'upload-signed-contract',
                        deal_id,
                        status,
                        info,
                        hostActive
                      )
                    "
                  >
                    {{
                      songFiles[song.id]?.length
                        ? "View Contract"
                        : "Upload Signed Contract"
                    }}
                  </button>
                  <button
                    v-if="!songFiles[song.id]?.length"
                    class="primary"
                    @click="
                      openModal('create', deal_id, status, info, hostActive)
                    "
                  >
                    Create Contract
                  </button>
                </template>

                <!--
              <button
                class="primary outline"
                @click="openModal('view', deal_id, status, info, song.isHost)"
                v-if="status === 'contract-ready'"
              >
                Print Contract
              </button>
              <button
                class="primary"
                @click="
                  openModal('fund', deal_id, status, info, song.isHost, fee)
                "
                :disabled="!chargesEnabled"
                :title="
                  !chargesEnabled ? 'This user has not enabled charges yet' : ''
                "
                v-if="
                  song.isHost &&
                    status === 'contract-ready' &&
                    fee_status === 'pending'
                "
              >
                Fund Deal
              </button>
              -->
                <button
                  class="primary"
                  @click="releaseFunds(deal_id, song.id)"
                  v-if="
                    hostActive &&
                      status === 'contract-ready' &&
                      ['requires_capture', 'processing'].includes(fee_status)
                  "
                  :disabled="
                    fee_status === 'processing' || submitting === deal_id
                  "
                >
                  {{
                    submitting === deal_id ? "Submitting..." : "Release Funds"
                  }}
                </button>
              </div>
              <ContractSteps :history="song.history" :artist_id="info.id" />
            </div>
          </template>
          <div
            v-if="
              !justMe &&
                song.collaborators.length === 1 &&
                song.isHost &&
                !song.contacts.length
            "
            class="card"
          >
            <div
              class="container flex flex-col items-start justify-start w-full gap-6 px-4"
            >
              <svg
                width="64"
                height="64"
                viewBox="0 0 64 64"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M32.0006 58.6693C46.7282 58.6693 58.6673 46.7302 58.6673 32.0026C58.6673 17.275 46.7282 5.33594 32.0006 5.33594C17.2731 5.33594 5.33398 17.275 5.33398 32.0026C5.33398 46.7302 17.2731 58.6693 32.0006 58.6693Z"
                  stroke="#666666"
                  stroke-width="4.26667"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M32 21.3359V32.0026"
                  stroke="#666666"
                  stroke-width="4.26667"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M32 42.668H32.0267"
                  stroke="#666666"
                  stroke-width="4.26667"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <h4 class="text-xl font-medium">
                There were no collaborators found, please confirm.
              </h4>

              <div class="flex items-center justify-center w-full gap-2">
                <button
                  class="flex-1 primary outline whitespace-nowrap"
                  @click="addingCollaborator = true"
                >
                  Wrong, add collaborators
                </button>
                <button class="flex-1 primary" @click="justMe = true">
                  Right, it's just me
                </button>
              </div>
            </div>
          </div>
          <div
            class="collaborators"
            v-if="song.contacts && song.contacts.length > 0"
          >
            <!-- <h2
              class="mb-4 text-xl font-bold text-left uppercase "
              style="width: calc(100%);"
            >
              Pending Collaborators
            </h2> -->

            <div
              v-for="pendingArtist in song.contacts.filter(
                contact =>
                  !artist.connected_entities?.includes(contact.entity_id)
              )"
              :key="
                pendingArtist.invited_artist_email ||
                  pendingArtist.artist_to_name + '_pending'
              "
              class="relative flex flex-col items-start justify-center w-full gap-5 card"
              style="width: calc(100%);"
            >
              <div class="absolute cursor-default select-none top-3 right-5">
                <svg
                  v-if="pendingArtist.invited_artist_email"
                  width="44"
                  height="33"
                  viewBox="0 0 44 33"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect y="0.5" width="44" height="32" rx="8" fill="#FFF0E0" />
                  <path
                    d="M15.4 8.7C15.4 8.11562 15.8812 7.6 16.5 7.6H17.6H26.4H27.5C28.0844 7.6 28.6 8.11562 28.6 8.7C28.6 9.31875 28.0844 9.8 27.5 9.8V10.1781C27.5 11.6562 26.9156 13.0656 25.8844 14.0969L23.5469 16.4L25.8844 18.7375C26.9156 19.7687 27.5 21.1781 27.5 22.6219V23C28.0844 23 28.6 23.5156 28.6 24.1C28.6 24.7188 28.0844 25.2 27.5 25.2H26.4H17.6H16.5C15.8812 25.2 15.4 24.7188 15.4 24.1C15.4 23.5156 15.8812 23 16.5 23V22.6219C16.5 21.1781 17.05 19.7687 18.0812 18.7375L20.4187 16.4L18.0812 14.0969C17.05 13.0656 16.5 11.6562 16.5 10.1781V9.8C15.8812 9.8 15.4 9.31875 15.4 8.7ZM18.7 9.8V10.1781C18.7 11.0719 19.0437 11.8969 19.6625 12.5156L22 14.8531L24.3031 12.5156C24.9219 11.8969 25.3 11.0719 25.3 10.1781V9.8H18.7ZM18.7 23H25.3V22.6219C25.3 21.7625 24.9219 20.9375 24.3031 20.3187L22 17.9812L19.6625 20.3187C19.0437 20.9375 18.7 21.7625 18.7 22.6562V23.0344V23Z"
                    fill="#FF8C00"
                  />
                </svg>

                <svg
                  v-else
                  width="44"
                  height="33"
                  viewBox="0 0 44 33"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect y="0.5" width="44" height="32" rx="8" fill="#FFE4E4" />
                  <path
                    d="M31.6594 22.2594C32.2781 23.3594 31.4875 24.7 30.2156 24.7H13.75C12.4781 24.7 11.6875 23.325 12.3062 22.2594L20.5562 7.925C21.175 6.825 22.7906 6.85937 23.4094 7.925L31.6594 22.2594ZM22 19.2687C21.1062 19.2687 20.4187 19.9906 20.4187 20.85C20.4187 21.7437 21.1062 22.4312 22 22.4312C22.8594 22.4312 23.5812 21.7437 23.5812 20.85C23.5812 19.9906 22.8594 19.2687 22 19.2687ZM20.4875 13.5969L20.7281 18.2719C20.7625 18.5125 20.9344 18.65 21.1406 18.65H22.825C23.0312 18.65 23.2031 18.5125 23.2375 18.2719L23.4781 13.5969C23.5125 13.3562 23.3062 13.15 23.0656 13.15H20.9C20.6594 13.15 20.4531 13.3562 20.4875 13.5969Z"
                    fill="#A62D2D"
                  />
                </svg>
              </div>

              <div class="top">
                <div class="artist">
                  <div class="avatar">
                    <div class="picture" v-if="pendingArtist.avatar_url">
                      <img :src="pendingArtist.avatar_url" />
                    </div>
                    <div class="initials" v-else>
                      {{
                        pendingArtist.artist_to_name ||
                          pendingArtist.invited_artist_email | initials
                      }}
                    </div>
                  </div>
                  <div
                    class="flex flex-col items-start justify-start flex-1 pl-4 font-medium truncate name"
                  >
                    <span>
                      {{ pendingArtist.artist_to_name }}
                      <span class="text-xs font-medium text-gray-500">{{
                        pendingArtist.invited_artist_email
                      }}</span>
                    </span>
                    <span class="text-xs font-medium text-gray-500">
                      {{ getArtistRoles(pendingArtist.entity_id) }}
                    </span>
                  </div>
                </div>
              </div>
              <div
                class="flex flex-col items-start justify-start flex-1 w-full px-4 font-medium truncate name"
              >
                <div
                  v-if="!pendingArtist.invited_artist_email"
                  style="background-color: #FFE4E4;"
                  class="flex items-center w-full max-w-md px-4 py-2 space-x-2 rounded-lg"
                >
                  <svg
                    width="22"
                    height="19"
                    viewBox="0 0 22 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20.6594 16.2594C21.2781 17.3594 20.4875 18.7 19.2156 18.7H2.75C1.47812 18.7 0.687499 17.325 1.30625 16.2594L9.55625 1.925C10.175 0.825 11.7906 0.859375 12.4094 1.925L20.6594 16.2594ZM11 13.2687C10.1062 13.2687 9.41875 13.9906 9.41875 14.85C9.41875 15.7437 10.1062 16.4312 11 16.4312C11.8594 16.4312 12.5812 15.7437 12.5812 14.85C12.5812 13.9906 11.8594 13.2687 11 13.2687ZM9.4875 7.59687L9.72812 12.2719C9.7625 12.5125 9.93437 12.65 10.1406 12.65H11.825C12.0312 12.65 12.2031 12.5125 12.2375 12.2719L12.4781 7.59687C12.5125 7.35625 12.3062 7.15 12.0656 7.15H9.9C9.65937 7.15 9.45312 7.35625 9.4875 7.59687Z"
                      fill="#A62D2D"
                    />
                  </svg>

                  <span
                    style="color: #A62D2D;"
                    class="flex-1 font-medium truncate"
                  >
                    <span class="font-bold"> You </span>must invite
                    {{ pendingArtist.artist_to_name }} to Nvoko.</span
                  >
                </div>
                <template v-else>
                  <div
                    style="background-color: #FFF0E0;"
                    class="flex items-center max-w-md px-4 py-2 space-x-2 rounded-lg "
                  >
                    <svg
                      width="14"
                      height="18"
                      viewBox="0 0 14 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.4 1.2C0.4 0.615625 0.88125 0.0999997 1.5 0.0999997H2.6H11.4H12.5C13.0844 0.0999997 13.6 0.615625 13.6 1.2C13.6 1.81875 13.0844 2.3 12.5 2.3V2.67812C12.5 4.15625 11.9156 5.56562 10.8844 6.59687L8.54687 8.9L10.8844 11.2375C11.9156 12.2687 12.5 13.6781 12.5 15.1219V15.5C13.0844 15.5 13.6 16.0156 13.6 16.6C13.6 17.2188 13.0844 17.7 12.5 17.7H11.4H2.6H1.5C0.88125 17.7 0.4 17.2188 0.4 16.6C0.4 16.0156 0.88125 15.5 1.5 15.5V15.1219C1.5 13.6781 2.05 12.2687 3.08125 11.2375L5.41875 8.9L3.08125 6.59687C2.05 5.56562 1.5 4.15625 1.5 2.67812V2.3C0.88125 2.3 0.4 1.81875 0.4 1.2ZM3.7 2.3V2.67812C3.7 3.57187 4.04375 4.39687 4.6625 5.01562L7 7.35312L9.30312 5.01562C9.92187 4.39687 10.3 3.57187 10.3 2.67812V2.3H3.7ZM3.7 15.5H10.3V15.1219C10.3 14.2625 9.92187 13.4375 9.30312 12.8187L7 10.4812L4.6625 12.8187C4.04375 13.4375 3.7 14.2625 3.7 15.1562V15.5344V15.5Z"
                        fill="#FF8C00"
                      />
                    </svg>

                    <span style="color: #FF8C00;" class="font-medium ">
                      <span class="font-bold">
                        Waiting on {{ pendingArtist.artist_to_name }}
                      </span>
                      to set up their account.
                    </span>
                  </div>
                  <div class="flex items-center justify-end w-full gap-4 pt-4">
                    <button
                      :disabled="inviteLoading"
                      class="primary outline"
                      @click="resendInvite(pendingArtist)"
                    >
                      Resend Invite
                    </button>
                    <button
                      class="primary"
                      @click="copyInviteLink(pendingArtist)"
                    >
                      <span class="flex items-center justify-center gap-x-2">
                        <svg
                          width="21"
                          height="20"
                          viewBox="0 0 21 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M17.1667 7.5H9.66667C8.74619 7.5 8 8.24619 8 9.16667V16.6667C8 17.5871 8.74619 18.3333 9.66667 18.3333H17.1667C18.0871 18.3333 18.8333 17.5871 18.8333 16.6667V9.16667C18.8333 8.24619 18.0871 7.5 17.1667 7.5Z"
                            stroke="white"
                            stroke-width="1.66667"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M4.66602 12.5013H3.83268C3.39065 12.5013 2.96673 12.3257 2.65417 12.0131C2.34161 11.7006 2.16602 11.2767 2.16602 10.8346V3.33464C2.16602 2.89261 2.34161 2.46868 2.65417 2.15612C2.96673 1.84356 3.39065 1.66797 3.83268 1.66797H11.3327C11.7747 1.66797 12.1986 1.84356 12.5112 2.15612C12.8238 2.46868 12.9993 2.89261 12.9993 3.33464V4.16797"
                            stroke="white"
                            stroke-width="1.66667"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>

                        Copy Invite Link
                      </span>
                    </button>
                  </div>
                </template>
              </div>

              <form
                v-if="song.isHost && !pendingArtist.invited_artist_email"
                @submit.prevent="inviteCollaborator"
                class="flex items-center justify-between w-full gap-2 px-4"
              >
                <input
                  type="hidden"
                  name="contact_id"
                  :value="pendingArtist.id"
                />
                <input
                  type="text"
                  class="flex-1 max-w-sm px-4 py-2 bg-gray-100 rounded-full"
                  placeholder="Enter Email Address"
                  name="email"
                />
                <button :disabled="inviteLoading" class="primary">
                  Invite
                </button>
              </form>
            </div>
          </div>
        </div>
        <div
          class="add-collaborator"
          v-if="
            song.isHost &&
              (justMe ||
                song.collaborators.length > 1 ||
                song.contacts.length > 0)
          "
        >
          <button class="primary" @click="addingCollaborator = true">
            <img src="../assets/icons/Track-Note.svg" />
            Add Collaborator
          </button>
        </div>
      </div>
    </div>
    <AddCollaborator
      v-if="addingCollaborator"
      @close="success => collaboratorAdded(success)"
      :song="song"
      :host_id="artist.id"
    />
    <ConnectToRelease
      v-if="modalName === 'connect-to-release'"
      :song="song"
      @close="clearDeal"
    />
    <CreateDeal
      v-if="modalName === 'create-deal'"
      @close="clearDeal"
      :deal="currentDeal"
    />
    <ReviewDeal
      v-if="modalName === 'review-deal'"
      @close="clearDeal"
      :deal="currentDeal"
    />
    <ReviewDealDropin
      v-if="modalName === 'review-deal-dropin'"
      @close="clearDeal"
      :deal="currentDeal"
    />
    <ViewDealTerms
      v-if="modalName === 'view-terms'"
      @close="clearDeal"
      :deal="currentDeal"
    />
    <ViewContract
      v-if="modalName === 'view-contract'"
      @close="clearDeal"
      :deal="currentDeal"
    />
    <ProjectFunding
      v-if="modalName === 'project-funding'"
      @close="clearDeal"
      :deal="currentDeal"
    />
    <InducementContract
      v-if="modalName === 'inducement-contract'"
      @close="clearDeal"
      :deal="currentDeal"
      :inducement="inducement"
    />
    <PaymentDropinModal
      v-if="modalName === 'payment-dropin'"
      @close="clearDeal"
      :deal="currentDeal"
    />
    <PaymentDoneOffPlatform
      v-if="modalName === 'payment-done-off-platform'"
      @close="onCloseDoneOffPlatform"
      :deal="currentDeal"
    />
    <UploadContractTerms
      v-if="modalName === 'upload-signed-contract'"
      @close="closeContractModal"
      @upload-complete="onUploadComplete"
      :deal="currentDeal"
      :song="song"
    />
  </div>
</template>

<script>
import AddCollaborator from "@/components/modals/AddCollaborator";
import CreateDeal from "@/components/modals/CreateDeal";
import ReviewDeal from "@/components/modals/ReviewDeal";
import ReviewDealDropin from "@/components/modals/ReviewDealDropin";
import ViewDealTerms from "@/components/modals/ViewDealTerms";
import ViewContract from "@/components/modals/ViewContract";
import ProjectFunding from "@/components/modals/ProjectFunding";
import InducementContract from "@/components/modals/InducementContract";
import ContractSteps from "@/components/ContractSteps";
import ConnectToRelease from "@/components/modals/ConnectToRelease";
import UploadContractTerms from "@/components/modals/UploadContractTerms";
// import PaymentDropin from "@/components/PaymentDropin";
import GET_SONGS from "@/api/queries/GET_SONGS.gql";
import RELEASE_FUNDS from "@/api/mutations/RELEASE_FUNDS.gql";
import UPDATE_SONG from "@/api/mutations/UPDATE_SONG.gql";
import UPDATE_DEAL from "@/api/mutations/UPDATE_DEAL.gql";
import INVITE_COLLABORATOR_JAXSTA from "@/api/mutations/INVITE_COLLABORATOR_JAXSTA.gql";
import PaymentDropinModal from "@/components/modals/PaymentDropinModal";
import PaymentDoneOffPlatform from "@/components/modals/PaymentDoneOffPlatform.vue";
import Icon from "@/components/Icon.vue";
import GET_SONG_FILES from "@/api/queries/GET_SONG_FILES.gql";
import GET_SONG_UPLOADED_CONTRACTS from "@/api/queries/GET_SONG_UPLOADED_CONTRACTS.gql";
import GET_TRACK_ARTISTS from "@/api/queries/GET_TRACK_ARTISTS.gql";

export default {
  name: "SongPreview",
  components: {
    AddCollaborator,
    CreateDeal,
    ReviewDeal,
    ViewDealTerms,
    ViewContract,
    ProjectFunding,
    InducementContract,
    ContractSteps,
    // PaymentDropin,
    PaymentDropinModal,
    ReviewDealDropin,
    PaymentDoneOffPlatform,
    Icon,
    ConnectToRelease,
    UploadContractTerms
  },
  props: {
    songs: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      addingCollaborator: false,
      currentDeal: null,
      inducement: null,
      modalName: "",
      submitting: "",
      companyQuestion: {
        answer: {},
        choices: []
      },
      loading: false,
      isEditingName: false,
      justMe: false,
      inviteLoading: false,
      songFiles: {},
      trackArtists: []
    };
  },
  async mounted() {
    this.updateCompanyQuestion();
    if (
      this.companyOptions.applicable &&
      this.song?.collaborators?.length > 1
    ) {
      this.updateCompanyData();
    }
    if (this.song?.id) {
      await this.fetchSongFiles(this.song.id);
    }
  },
  watch: {
    async "song.id"(newId) {
      this.updateCompanyQuestion();
      this.justMe = false;
      if (newId) {
        await this.fetchSongFiles(newId);
      }
    }
  },
  computed: {
    song() {
      const { song: songId } = this.$route.query;
      if (!songId || !this.songs) return {};
      const foundSong = this.songs.find(el => el.id === songId);
      return foundSong || {};
    },
    dealStatus() {
      return this.song.collaborators?.[1]?.status || "not-started";
    },
    artist() {
      return this.$store.getters["account/getArtist"];
    },
    companyOptions() {
      const applicable = typeof this.song.isPartyCompany !== "boolean";
      const valid = typeof this.companyQuestion.answer.value === "string";
      return { applicable, valid };
    },
    songStatus() {
      return this.song.collaborators?.[1]?.status;
    }
  },
  filters: {
    initials(name) {
      return name
        ?.split(" ")
        .slice(0, 2)
        .map(el => el.charAt(0).toUpperCase())
        .join("");
    },
    contribution(data) {
      // Add null check and ensure data is an array
      if (!data || !Array.isArray(data)) return "";

      return data
        .map(el => el.charAt(0).toUpperCase() + el.slice(1))
        .join(", ");
    },
    currency(value) {
      if (!value) return "$0";
      return Number(value).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: 0
      });
    }
  },
  methods: {
    hasPlatform(platform) {
      console.log({ releases: this.song.external_releases });
      return this.song.external_releases.some(
        release => release.type === platform
      );
    },
    openConnectToRelease() {
      this.modalName = "connect-to-release";
    },
    copyInviteLink(artist) {
      const inviteLink = `${window.location.origin}/invite/${artist.id}`;
      navigator.clipboard
        .writeText(inviteLink)
        .then(() => {
          this.$toast.success("Invite link copied to clipboard!", {});
        })
        .catch(err => {
          console.error("Failed to copy invite link:", err);
          this.$toast.error(
            "Failed to copy invite link. Please try again.",
            {}
          );
        });
    },
    editSongName() {
      this.isEditingName = true;
      this.tempSongName = this.song.name;
    },
    async inviteCollaborator(e) {
      try {
        const email = e.target.email.value;
        const contact_id = e.target.contact_id.value;
        if (!email) return;
        this.inviteLoading = true;
        await this.$apollo.mutate({
          mutation: INVITE_COLLABORATOR_JAXSTA,
          variables: { email, contact_id }
        });
        this.$emit("refetchSongs");
      } catch (error) {
        console.error("Error inviting collaborator:", error);
      } finally {
        this.inviteLoading = false;
      }
    },
    refetchSongs() {
      this.$emit("refetchSongs");
    },
    async closeContractModal() {
      this.clearDeal();
      if (this.song?.id) {
        await this.fetchSongFiles(this.song.id);
      }
      this.$emit("refetchSongs");
    },
    async resendInvite(artist) {
      console.log({ artist });
      try {
        const email = artist.invited_artist_email;
        const contact_id = artist.id;
        if (!email) return;
        this.inviteLoading = true;
        await this.$apollo.mutate({
          mutation: INVITE_COLLABORATOR_JAXSTA,
          variables: { email, contact_id }
        });
        this.$toast.success("Invite resent!", {});
      } catch (error) {
        console.error("Error inviting collaborator:", error);
        this.$toast.error("Error inviting collaborator. Please try again.", {});
      } finally {
        this.inviteLoading = false;
      }
    },
    async saveSongName() {
      this.isEditingName = false;
      if (this.tempSongName !== this.song.name) {
        try {
          await this.$apollo.mutate({
            mutation: UPDATE_SONG,
            variables: {
              id: this.song.id,
              update: { name: this.tempSongName }
            },
            update: (store, { data: { update_songs_by_pk } }) => {
              const data = store.readQuery({
                query: GET_SONGS,
                variables: {
                  artistId: this.$store.getters["account/getArtistId"]
                }
              });

              const songIndex = data.songs.findIndex(
                el => el.id === this.song.id
              );
              data.songs[songIndex] = {
                ...data.songs[songIndex],
                ...update_songs_by_pk
              };

              store.writeQuery({
                query: GET_SONGS,
                variables: {
                  artistId: this.$store.getters["account/getArtistId"]
                },
                data
              });
            }
          });

          this.$emit("refetchSongs");
        } catch (e) {
          console.error(e);
        }
      }
    },

    onCloseDoneOffPlatform(reload = false) {
      this.clearDeal();
      if (reload) this.$emit("refetchSongs");
    },
    setCurrentDeal(type, deal_id, status, artist, hostActive, fee) {
      const { collaborators } = this.song;
      console.log(this.song);
      const songHost = collaborators[0].info;
      const songHostActive =
        songHost.id === this.$store.getters["account/getArtistId"];
      this.currentDeal = {
        myArtist: songHostActive ? songHost : artist,
        artist: songHostActive ? artist : songHost,
        party: hostActive ? "host" : "guest",
        song_id: this.song.id,
        song_name: this.song.name,
        purchased: this.song.purchased,
        id: deal_id,
        paid_by: this.song?.paid_by,
        status,
        fee,
        split: collaborators.flatMap(({ deal_id, percentage }) =>
          deal_id ? [{ deal_id, percentage }] : []
        )
      };
    },
    async checkSongFiles(song_id) {
      if (this.songFiles[song_id] !== undefined) {
        return this.songFiles[song_id];
      }

      try {
        const { data } = await this.$apollo.query({
          query: GET_SONG_UPLOADED_CONTRACTS,
          variables: { song_id }
        });
        console.log("data >>>>>>>>>", data);

        this.songFiles[song_id] = data.files.length > 0;
        return this.songFiles[song_id];
      } catch (error) {
        console.error("Error checking song files:", error);
        return false;
      }
    },
    async openModal(
      type,
      deal_id,
      status,
      artist,
      hostActive,
      inducement,
      fee
    ) {
      const { collaborators } = this.song;
      const songHost = collaborators[0].info;
      const songHostActive =
        songHost.id === this.$store.getters["account/getArtistId"];
      this.currentDeal = {
        myArtist: songHostActive ? songHost : artist,
        artist: songHostActive ? artist : songHost,
        party: hostActive ? "host" : "guest",
        song_id: this.song.id,
        song_name: this.song.name,
        purchased: this.song.purchased,
        paid_by: this.song?.paid_by,
        id: deal_id,
        status,
        fee,
        split: collaborators.flatMap(({ deal_id, percentage }) =>
          deal_id ? [{ deal_id, percentage }] : []
        )
      };

      switch (type) {
        case "pay":
          this.modalName = "payment-dropin";
          break;
        case "create":
          this.modalName = this.hasSongFiles() ? "view-terms" : "create-deal";
          break;
        case "review":
          this.modalName = "review-deal-dropin";
          break;
        case "view-terms":
          this.modalName = "view-terms";
          break;
        case "view":
          this.modalName = "view-contract";
          break;
        case "payment-done-off-platform":
          this.modalName = "payment-done-off-platform";
          break;
        case "fund":
          this.modalName = "project-funding";
          break;
        case "inducement":
          this.inducement = inducement;
          this.modalName = "inducement-contract";
          break;
        case "upload-signed-contract":
          this.modalName = "upload-signed-contract";
          break;
        default:
          break;
      }
    },
    openPaymentDropin() {
      const collaborator = this.song.collaborators[1];
      this.openModal(
        "pay",
        collaborator.deal_id,
        status,
        collaborator.info,
        true
      );
    },
    openPaymentDoneOffPlatformModal() {
      const collaborator = this.song.collaborators[1];
      this.openModal(
        "payment-done-off-platform",
        collaborator.deal_id,
        status,
        collaborator.info,
        true
      );
    },
    clearDeal() {
      this.currentDeal = null;
      this.inducement = null;
      this.modalName = "";
    },
    updateCompanyQuestion() {
      const choices = this.artist.company_artists.flatMap(
        ({ company, recording_agreement, recording_agreement_third_party }) => {
          if (!company) return [];
          return [
            {
              value: company.id,
              label: company.name,
              recording_agreement,
              recording_agreement_third_party
            }
          ];
        }
      );

      choices.unshift({
        value: "",
        label: this.artist.name,
        recording_agreement: this.artist.company_artists.some(
          ({ recording_agreement }) => !!recording_agreement
        )
      });

      this.$set(this.companyQuestion, "choices", choices);
      this.$set(this.companyQuestion, "answer", {});
    },
    updateCompanyData() {
      const {
        song: { isHost },
        updateHostCompanyData,
        updateGuestCompanyData
      } = this;

      this.loading = true;

      return isHost ? updateHostCompanyData() : updateGuestCompanyData();
    },
    updateHostCompanyData() {
      const {
        song: { id },
        companyQuestion: {
          answer: {
            value,
            recording_agreement,
            recording_agreement_third_party
          }
        }
      } = this;

      this.submitting = id;
      let update = {};

      if (!value)
        update = { is_host_company: false, rds: recording_agreement ? 4 : 5 };
      else {
        let rds;
        if (recording_agreement) rds = recording_agreement_third_party ? 2 : 1;
        else rds = recording_agreement_third_party ? 2 : 3;

        update = { is_host_company: true, host_company_id: value, rds };
      }

      this.$apollo.mutate({
        mutation: UPDATE_SONG,
        variables: { id, update },
        update: (store, { data: { update_songs_by_pk } }) => {
          const data = store.readQuery({
            query: GET_SONGS,
            variables: {
              artistId: this.$store.getters["account/getArtistId"]
            }
          });

          const song = data.songs.findIndex(el => el.id === id);
          data.songs[song] = { ...data.songs[song], ...update_songs_by_pk };

          store.writeQuery({
            query: GET_SONGS,
            variables: {
              artistId: this.$store.getters["account/getArtistId"]
            },
            data
          });

          this.submitting = "";
          this.loading = false;
          this.$emit("close");
        }
      });
    },
    updateGuestCompanyData() {
      const {
        song: { id, myDeal },
        companyQuestion: {
          answer: { value }
        }
      } = this;

      this.submitting = id;
      let update = {};

      if (!value) update = { is_guest_company: false };
      else update = { is_guest_company: true, guest_company_id: value };

      this.$apollo.mutate({
        mutation: UPDATE_DEAL,
        variables: { id: myDeal, update },
        update: (store, { data: { update_deals_by_pk } }) => {
          const data = store.readQuery({
            query: GET_SONGS,
            variables: {
              artistId: this.$store.getters["account/getArtistId"]
            }
          });

          const song = data.songs.findIndex(el => el.id === id);
          const index = data.songs[song].splits[0].split_details.findIndex(
            el => el.deal_id === myDeal
          );

          data.songs[song].splits[0].split_details[index].deal = {
            ...data.songs[song].splits[0].split_details[index].deal,
            ...update_deals_by_pk
          };

          store.writeQuery({
            query: GET_SONGS,
            variables: {
              artistId: this.$store.getters["account/getArtistId"]
            },
            data
          });

          this.submitting = "";
          this.loading = false;
          this.$emit("close");
        }
      });
    },
    collaboratorAdded(success) {
      this.addingCollaborator = false;
      if (success) this.$emit("refetchSongs");
    },
    releaseFunds(deal, song_id) {
      this.submitting = deal;

      this.$apollo.mutate({
        mutation: RELEASE_FUNDS,
        variables: { deal },
        update: (store, { data: { stripe_capture_funds } }) => {
          if (!stripe_capture_funds.success) return;
          const data = store.readQuery({
            query: GET_SONGS,
            variables: {
              artistId: this.$store.getters["account/getArtistId"]
            }
          });

          const song = data.songs.findIndex(el => el.id === song_id);
          const index = data.songs[song].splits[0].split_details.findIndex(
            el => el.deal_id === deal
          );

          data.songs[song].splits[0].split_details[index].deal = {
            ...data.songs[song].splits[0].split_details[index].deal,
            fee_status: "succeeded"
          };

          store.writeQuery({
            query: GET_SONGS,
            variables: {
              artistId: this.$store.getters["account/getArtistId"]
            },
            data
          });

          this.submitting = "";
          this.$emit("close");
        }
      });
    },
    async fetchSongFiles(song_id) {
      try {
        const { data } = await this.$apollo.query({
          query: GET_SONG_UPLOADED_CONTRACTS,
          variables: { song_id },
          fetchPolicy: "network-only" // Force a fresh fetch from server
        });

        // Update the songFiles object with the new data
        this.$set(this.songFiles, song_id, data.files);
      } catch (error) {
        console.error("Error fetching song files:", error);
        this.$set(this.songFiles, song_id, []);
      }
    },
    hasSongFiles() {
      return this.song?.files?.length > 0;
    },
    async onUploadComplete(files) {
      // Refresh the song files
      if (this.song?.id) {
        await this.fetchSongFiles(this.song.id);
      }
      // Refresh the songs list to update any status changes
      this.$emit("refetchSongs");
    },
    getArtistRoles(entityId) {
      if (!entityId) return "";

      if (!this.trackArtists || !Array.isArray(this.trackArtists)) {
        return "";
      }

      const artist = this.trackArtists.find(a => a.entity_id === entityId);
      if (!artist) return "";

      if (Array.isArray(artist.roles) && artist.roles.length) {
        return artist.roles.join(", ");
      }

      if (Array.isArray(artist.role_groups) && artist.role_groups.length) {
        return artist.role_groups.join(", ");
      }

      return "No role specified";
    }
  },
  apollo: {
    trackArtists: {
      query: GET_TRACK_ARTISTS,
      variables() {
        const trackId =
          this.song?.imported_track_id || this.song?.spotify_id || "";
        return {
          imported_track_id: trackId
        };
      },
      skip() {
        const shouldSkip =
          !this.song?.imported_track_id && !this.song?.spotify_id;
        return shouldSkip;
      },
      result({ data, loading }) {
        this.trackArtists = data.imported_track_artists;
      }
    }
  },
  mounted() {
    this.updateCompanyQuestion();
    if (
      this.companyOptions.applicable &&
      this.song?.collaborators?.length > 1
    ) {
      this.updateCompanyData();
    }
    if (this.song?.id) {
      this.fetchSongFiles(this.song.id);
    }
    console.log("song.contacts >>>>>>>>>", this.song.contacts);
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/_mixins.scss";

.right-panel {
  display: flex;
  height: 100%;
  flex-direction: column;
  // background: $bg;
  border: 1px solid #e2e2e2;
  border-radius: 20px;
  overflow-y: hidden;
  // scrollbar-width: thin;
  // scrollbar-color: $accent rgba(#cdcccc, 0.3);

  .panel-container-outer {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 1.25rem;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: $accent rgba(#cdcccc, 0.3);

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      @include background-opacity(#cdcccc, 0.3);
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $accent;
      border-radius: 5px;
    }

    .panel-header {
      font-size: 1.7rem;
    }
  }
  .panel-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 1.25rem;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: $accent rgba(#cdcccc, 0.3);

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      @include background-opacity(#cdcccc, 0.3);
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $accent;
      border-radius: 5px;
    }

    .panel-header {
      font-size: 1.7rem;
    }

    .collaborators {
      margin-top: 1.75rem;

      .card {
        padding: 16px 0;
        margin-bottom: 31px;
        border: 1px solid #bdbdbd;
        border-radius: 10px;
        background-color: $white;
        // box-shadow: 0px 16px 24px rgb(59 59 59 / 8%);

        &.company {
          padding: 0 10px;
          display: flex;
          flex-direction: column;

          .step {
            display: flex;
            flex-direction: column;
            flex: 1;
            margin-top: 42px;

            .question {
              width: 100%;
              height: fit-content;
              font-weight: 500;
              font-size: 20px;
              line-height: 22px;
              text-align: center;
              letter-spacing: -1.025px;
              color: $text;
              margin-bottom: 24px;
            }

            .inputs {
              display: flex;
              flex-direction: column;
              align-items: center;
              width: 100%;

              .radiobuttons {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;

                .radio-group {
                  .radio {
                    width: 144px;
                    text-align: center;
                    padding: 10px;
                  }
                }
              }
            }
          }

          button {
            margin-top: -10px;
            margin-bottom: 18px;
            margin-left: auto;
            margin-right: auto;
          }
        }

        .top {
          display: flex;
          width: 100%;
          padding: 0 20px;

          .artist {
            display: flex;
            flex: 1;
            align-items: center;

            img {
              width: 55px;
              height: 55px;
              border-radius: 50%;
            }

            .initials {
              display: flex;
              justify-content: center;
              align-items: center;
              height: 55px;
              width: 55px;
              font-family: sans-serif;
              font-size: 22px;
              border-radius: 50%;
              background-color: $black;
              border: 1px solid $accent;
              color: $white;
            }

            .details {
              margin-left: 1rem;
              font-size: 1.25rem;
              display: flex;
              flex-direction: column;

              .contribution {
                @include details-light();
              }
            }
          }

          .party-icon {
            user-select: none;
            background-color: black;
            color: white;
            font-size: 15px;
            height: 28px;
            border-radius: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 4px 8px;
            font-weight: 600;
          }

          .check {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 1px;
            background-color: #f5ffe4;
            padding: 8px 12px;
            border-radius: 8px;
            color: #7aa62d;
            font-size: 0.75rem;
            font-weight: 600;
            svg {
              width: 20px;
              height: 20px;
              stroke: currentColor;
            }
          }
        }

        .split-name {
          margin: 10px 20px 0;
          font-size: 1rem;
        }

        .split {
          padding-top: 0.75rem;
          display: flex;

          div {
            margin: 0 20px;
            display: flex;
            flex-direction: column;

            .type {
              @include details-light();
              color: rgb(155, 155, 155);
              // font-weight: 700;
            }

            .percentage {
              margin-top: 0.35rem;
              font-family: "Roboto Mono";
              font-size: 1.3rem;
            }

            .invalid {
              color: $accent;
            }
          }
        }

        .buttons {
          padding: 0.5rem 20px 0;
          display: flex;
          justify-content: flex-end;

          button {
            display: flex;
            margin-left: 5px;

            img {
              margin-right: 0.5rem;
            }
          }
        }
      }
    }

    .add-collaborator {
      width: 100%;
      display: flex;
      justify-content: center;
      padding: 1.5rem;
      border-radius: 10px;

      border: 1px dotted $accent;

      button {
        display: flex;
        padding: 0.75rem 2.5rem;

        img {
          margin-right: 0.5rem;
        }
      }
    }
  }
}

.deal-status {
  padding: 0.75rem 20px;

  .item {
    border: 1px #00000000 solid;
    border-radius: 10px;
    padding: 10px 15px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    background-color: rgb(252, 238, 242);

    .type {
      // @include details-light();
      color: rgb(227, 80, 122);
      font-weight: 700;
    }

    .status {
      font-size: 1.25rem;
      margin-left: 0.25rem;
      @include details-light();
      color: rgb(232, 110, 145);
    }

    button {
      margin-left: auto;
    }
  }
}

.artists-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: calc(100vh - 204px);
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: $accent rgba(#cdcccc, 0.3);

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    @include background-opacity(#cdcccc, 0.3);
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $accent;
    border-radius: 5px;
  }

  &:after {
    content: "";
    flex: 1;
  }
}

.artist-card {
  display: flex;
  align-items: center;
  width: calc(100%);
  padding: 8px;
  background-color: #f6f6f6;
  border: 2px solid #f6f6f6;
  border-radius: 10px;
  margin-bottom: 20px;
  cursor: pointer;

  &.active,
  &:hover {
    border: 2px solid #838383;
    box-shadow: 0px 16px 24px rgba(59, 59, 59, 0.08);
  }

  &.active {
    background: #ffffff;
    border: 2px solid $accent;
  }

  .avatar {
    .picture {
      img {
        height: 57px;
        width: 57px;
        object-fit: cover;
        border-radius: 50%;
      }
    }

    .initials {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 57px;
      width: 57px;
      font-family: sans-serif;
      font-size: 20px;
      background-color: $black;
      border: 1px solid $accent;
      color: $white;
      border-radius: 50%;
    }
  }

  .name {
    margin-left: 16px;
    flex: 1;
    color: $text;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: -0.2475px;
    margin-right: 4px;
  }
}

.cards {
  width: 100%;
  margin-bottom: 20px;
  border-radius: 10px;
  padding: 16px;
  background: #fff;
  box-shadow: 0px 16px 24px rgb(59 59 59 / 8%);
}
</style>
