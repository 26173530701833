<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <Loader
          v-if="$apollo.queries.contract_questions.loading"
          color="#FF035A"
          size="50"
        />
        <div v-else class="modal-container">
          <div class="modal-header">
            <slot name="header">
              <div @click="$emit('close')" class="icon">
                <ModalCloseIcon :accent="false" />
              </div>
              <span class="progress">
                {{ `${step} / ${questions.length}` }}
              </span>
            </slot>
          </div>

          <div class="modal-body">
            <slot name="body">
              <ContractQuestion v-model="currentQuestion" />
              <div class="buttons">
                <button v-if="step > 1" @click="step--" class="primary outline">
                  Previous
                </button>
                <button
                  v-if="step < questions.length"
                  :disabled="stepInvalid"
                  @click="step++"
                  class="primary"
                >
                  Next
                </button>
                <button
                  v-if="step === questions.length"
                  :disabled="stepInvalid || submitting"
                  @click="createContract"
                  class="primary"
                >
                  {{ submitting ? "Submitting..." : "Create Contract" }}
                </button>
              </div>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import ContractQuestion from "@/components/DealTerm.vue";
import ModalCloseIcon from "@/assets/icons/Modal-Close.vue";
import Loader from "@/components/Loader.vue";

import GET_DEAL from "@/api/queries/GET_DEAL.gql";
import GET_CONTRACT_QUESTIONS from "@/api/queries/GET_CONTRACT_QUESTIONS.gql";
import CREATE_CONTRACT from "@/api/mutations/CREATE_CONTRACT.gql";
import INSERT_CONTRACT_ANSWERS from "@/api/mutations/INSERT_CONTRACT_ANSWERS.gql";

export default {
  name: "ContractEditor",
  components: {
    ModalCloseIcon,
    Loader,
    ContractQuestion
  },
  data() {
    return {
      contract_id: null,
      questions: [],
      step: 1,
      submitting: false
    };
  },
  props: {
    deal: Object
  },
  watch: {
    contract_questions(questions) {
      this.questions = questions;
    }
  },
  computed: {
    currentQuestion() {
      const { questions, step } = this;
      return questions.length ? questions[step - 1] : {};
    },
    stepInvalid() {
      const { isStepValid, currentQuestion } = this;
      return !isStepValid(currentQuestion);
    }
  },
  methods: {
    checkConditions(conditions, isOR) {
      const { deal, checkConditions: check } = this;
      if (deal.party !== conditions.party && !isOR) return 0;

      for (const el in conditions) {
        if (deal[el] && isOR) return 1;
        if (!deal[el] && !isOR && el !== "_or") return 0;
        if (el === "_or" && !check(conditions[el], true)) return false;
      }

      return isOR ? 0 : 1;
    },
    formatQuestions(questions) {
      let formatted = [];

      const addSubs = question => {
        if (!question.choices) return { ...question, answer: {} };

        const choices = question.choices.map(choice => {
          let sub;
          if (choice.sub_id) {
            sub = questions.find(el => el.id === choice.sub_id);
            if (sub) sub = { ...addSubs(sub), answer: {} };
          }
          return { ...choice, sub };
        });

        return { ...question, choices, answer: {} };
      };

      questions.forEach(question => {
        if (!question.parent_id) formatted.push(addSubs(question));
      });

      return formatted;
    },
    isStepValid({ answer, type }) {
      const { isStepValid } = this;
      if (
        ["null", "undefined"].includes(typeof answer.value) ||
        (type !== "multiple_choice" && !answer.value)
      )
        return false;
      if (answer.sub && !isStepValid(answer.sub)) return false;
      return true;
    },
    parseAnswers() {
      const { questions, contract_id } = this;
      let answers = [];

      const getAnswers = ({ id, answer }) => {
        let response = [{ question_id: id, answer: { value: answer.value } }];
        if (contract_id) Object.assign(response[0], { contract_id });
        if (answer.sub) response.push.apply(response, getAnswers(answer.sub));
        return answers.find(answer => answer.question_id === id)
          ? []
          : response;
      };

      questions.forEach(question => {
        answers.push.apply(answers, getAnswers(question));
      });

      return answers;
    },
    createContract() {
      this.submitting = true;

      const {
        parseAnswers,
        contract_id,
        deal: {
          id: deal_id,
          party,
          artist: { id: toID },
          myArtist: { id: fromID, name: myArtistName }
        }
      } = this;

      const answers = parseAnswers();

      const message = {
        deal_id,
        artist_id_to: toID,
        artist_id_from: fromID,
        system_message: {
          recipient: `${myArtistName} has answered contract questions`,
          sender: "You have answered contract questions"
        }
      };

      const status = contract_id
        ? `contract-created`
        : `contract-${party}-answered`;

      this.$apollo.mutate({
        mutation: contract_id ? INSERT_CONTRACT_ANSWERS : CREATE_CONTRACT,
        variables: { deal_id, answers, message, status },
        update: (store, { data: { update_deals_by_pk } }) => {
          const data = store.readQuery({
            query: GET_DEAL,
            variables: {
              id: this.$route.query.view
            }
          });

          data.deals_by_pk = {
            ...data.deals_by_pk,
            ...update_deals_by_pk
          };

          this.submitting = false;

          store.writeQuery({
            query: GET_DEAL,
            data
          });
          this.$emit("close");
        }
      });
    }
  },
  apollo: {
    contract_questions: {
      query: GET_CONTRACT_QUESTIONS,
      variables() {
        const {
          deal: { id: deal_id }
        } = this;
        return { deal_id };
      },
      update({ contracts, contract_questions }) {
        console.log({ contract_questions });
        if (contracts.length) this.contract_id = contracts[0].id;

        const { checkConditions: check, formatQuestions } = this;
        const questions = contract_questions.flatMap(el => {
          const { conditions } = el;
          return check(conditions, false) ? [el] : [];
        });

        console.log({ qs: formatQuestions(questions) });
        return formatQuestions(questions);
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/styles/_mixins.scss";

.modal-container {
  width: 916px;
  height: 719px;
  // height: fit-content;
  // background: #ffffff;
  // box-shadow: -1px 0px 48px rgba(0, 0, 0, 0.0812937);
  // border-radius: 15px;
  max-height: calc(100vh - 80px);
  background: #ffffff;
  box-shadow: -1px 0px 48px rgba(0, 0, 0, 0.0812937);
  border-radius: 15px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.modal-header {
  flex-wrap: wrap;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  padding-bottom: 0;

  .icon {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .progress {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 25px;
    font-size: 30px;
    line-height: 30px;
    letter-spacing: 8px;
    color: $accent;
  }
}

.modal-body {
  padding: 10px 80px;
  padding-top: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: $accent rgba(#cdcccc, 0.3);

  &::-webkit-scrollbar {
    /* width of the entire scrollbar */
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    /* color of the tracking area */
    @include background-opacity(#cdcccc, 0.3);
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    /* color of the scroll thumb */
    background-color: $accent;
    /* roundness of the scroll thumb */
    border-radius: 5px;
  }

  .buttons {
    height: 5rem;
    padding: 1.5rem 0;
    display: flex;
    justify-content: flex-end;
    border-top: solid #f0f0f0 0.5px;

    button {
      margin: 0 5px;
      width: 169px;
      height: 50px;
      font-weight: bold;
      font-size: 16px;
      line-height: 16px;
    }
  }
}
</style>
