import axios from "axios";

const SPOTIFY_ACCESS_TOKEN = process.env.VUE_APP_SPOTIFY_ACCESS_TOKEN;
const APPLE_MUSIC_DEVELOPER_TOKEN =
  process.env.VUE_APP_APPLE_MUSIC_DEVELOPER_TOKEN;

export async function searchSpotify(query) {
  try {
    const response = await axios.get("https://api.spotify.com/v1/search", {
      params: {
        q: query,
        type: "track",
        limit: 10
      },
      headers: {
        Authorization: `Bearer ${SPOTIFY_ACCESS_TOKEN}`
      }
    });

    return response.data.tracks.items.map(item => ({
      id: item.id,
      title: item.name,
      artist: item.artists[0].name,
      album: item.album.name,
      artwork: item.album.images[0]?.url,
      provider: "spotify",
      isrc: item.external_ids.isrc
    }));
  } catch (error) {
    console.error("Spotify search error:", error);
    return [];
  }
}

export async function searchAppleMusic(query) {
  try {
    const response = await axios.get(
      "https://api.music.apple.com/v1/catalog/us/search",
      {
        params: {
          term: query,
          types: "songs",
          limit: 10
        },
        headers: {
          Authorization: `Bearer ${APPLE_MUSIC_DEVELOPER_TOKEN}`
        }
      }
    );

    return response.data.results.songs.data.map(item => ({
      id: item.id,
      title: item.attributes.name,
      artist: item.attributes.artistName,
      album: item.attributes.albumName,
      artwork: item.attributes.artwork.url.replace("{w}x{h}", "300x300"),
      provider: "apple music",
      isrc: item.attributes.isrc
    }));
  } catch (error) {
    console.error("Apple Music search error:", error);
    return [];
  }
}

export async function searchDeezer(query) {
  try {
    const response = await axios.get("https://api.deezer.com/2.0/search", {
      params: {
        q: query,
        limit: 10,
        output: "json"
      }
    });

    return response.data.data.map(item => ({
      id: item.id,
      title: item.title,
      artist: item.artist.name,
      album: item.album.title,
      artwork: item.album.cover_big,
      provider: "deezer",
      isrc: item.isrc
    }));
  } catch (error) {
    console.error("Deezer search error:", error);
    return [];
  }
}

export async function searchAllProviders(query) {
  return await Promise.all([
    searchSpotify(query),
    searchAppleMusic(query),
    searchDeezer(query)
  ]);
}
