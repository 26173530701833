<template>
  <UpdateDealTerm
    :deal="deal"
    v-if="updateQuestion"
    :update-question="updateQuestion"
    @close="closeUpdateModal"
  />
  <PreviewContract
    v-else-if="contractPreview"
    :contract="contract"
    @close="contractPreview = false"
  />
  <AddCard v-else-if="addingCard" @close="addingCard = false" />
  <transition name="modal" v-else>
    <div key="mask" class="modal-mask">
      <div class="modal-wrapper">
        <transition name="modal" mode="out-in">
          <Loader
            key="loader"
            v-if="
              $apollo.queries.answers.loading ||
                $apollo.queries.paymentMethods.loading
            "
            color="#FF035A"
            size="50"
          />
          <div key="content" v-else>
            <transition name="modal" mode="out-in">
              <div key="step-1" v-if="step == 1" class="modal-container">
                <div class="modal-header">
                  <slot name="header">
                    <div @click="$emit('close')" class="icon">
                      <ModalCloseIcon :accent="false" />
                    </div>
                    <div class="title">
                      <h2>Deal Terms</h2>
                    </div>
                  </slot>
                </div>

                <div class="modal-body">
                  <slot name="body">
                    <div class="step2">
                      <div class="relative answers">
                        <div
                          v-if="contractHasOutstandingChanges()"
                          class="sticky p-4 my-5 rounded-md bg-yellow-50"
                        >
                          <div class="flex">
                            <div class="flex-shrink-0">
                              <!-- <ExclamationTriangleIcon
                            class="w-5 h-5 text-yellow-400"
                            aria-hidden="true"
                          /> -->
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="mt-0.5 text-yellow-400 icon icon-tabler icon-tabler-alert-triangle-filled"
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="#ffffff"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <path
                                  stroke="none"
                                  d="M0 0h24v24H0z"
                                  fill="none"
                                />
                                <path
                                  d="M12 1.67c.955 0 1.845 .467 2.39 1.247l.105 .16l8.114 13.548a2.914 2.914 0 0 1 -2.307 4.363l-.195 .008h-16.225a2.914 2.914 0 0 1 -2.582 -4.2l.099 -.185l8.11 -13.538a2.914 2.914 0 0 1 2.491 -1.403zm.01 13.33l-.127 .007a1 1 0 0 0 0 1.986l.117 .007l.127 -.007a1 1 0 0 0 0 -1.986l-.117 -.007zm-.01 -7a1 1 0 0 0 -.993 .883l-.007 .117v4l.007 .117a1 1 0 0 0 1.986 0l.007 -.117v-4l-.007 -.117a1 1 0 0 0 -.993 -.883z"
                                  stroke-width="0"
                                  fill="currentColor"
                                />
                              </svg>
                            </div>
                            <div class="ml-3">
                              <h3 class="text-sm font-medium text-yellow-800">
                                Attention needed
                              </h3>
                              <div class="mt-2 text-sm text-yellow-700">
                                <p>
                                  There are (<b>{{
                                    contractHasOutstandingChanges()
                                  }}</b
                                  >) outstanding changes to the contract. Please
                                  acknowledge them before signing, or
                                  <button
                                    @click="acknowledgeAllChanges(contract)"
                                    style="color: #FF035A"
                                  >
                                    acknowledge all changes</button
                                  >.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          v-for="({ question, answer }, i) in answers"
                          :key="i"
                          class="answer"
                          :class="
                            answerIsNotAcknowledged(answer)
                              ? 'border-l-2 border-red-500 pl-2'
                              : ''
                          "
                        >
                          <div class="question-title">
                            <span class="text max-w-[calc(100%-14px)]">{{
                              question.title
                            }}</span>
                            <div
                              @click="updateQuestion = question.id"
                              class="icon"
                              v-if="canUpdate"
                            >
                              <PenIcon :accent="false" />
                            </div>
                          </div>
                          <div
                            class="file-link"
                            v-if="answer && answer.type === 'file'"
                          >
                            <FileLink :file="answer.file" />
                          </div>
                          <div v-else class="answer-value">
                            <p v-if="!answerIsNotAcknowledged(answer)">
                              {{ answer.value }}
                            </p>
                            <div
                              v-if="answerIsNotAcknowledged(answer)"
                              class="flex items-center justify-start gap-1 "
                            >
                              <span class="opacity-60">{{
                                getLastChange(answer).answer_from.value
                              }}</span>
                              →
                              <span style="color: #FF035A">
                                {{ getLastChange(answer).answer_to.value }}
                              </span>
                              <div
                                class="flex items-center justify-start gap-1 pl-5 "
                              >
                                <button @click="updateQuestion = question.id">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="icon icon-tabler icon-tabler-x"
                                    width="20"
                                    height="20"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="#FF035A"
                                    fill="none"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  >
                                    <path
                                      stroke="none"
                                      d="M0 0h24v24H0z"
                                      fill="none"
                                    />
                                    <path d="M18 6l-12 12" />
                                    <path d="M6 6l12 12" />
                                  </svg>
                                </button>
                                <button @click="acknowledgeChange(answer)">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="icon icon-tabler icon-tabler-check"
                                    width="20"
                                    height="20"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="#00b341"
                                    fill="none"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  >
                                    <path
                                      stroke="none"
                                      d="M0 0h24v24H0z"
                                      fill="none"
                                    />
                                    <path d="M5 12l5 5l10 -10" />
                                  </svg>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </slot>
                </div>

                <div class="modal-footer">
                  <slot name="footer">
                    <div class="buttons">
                      <button
                        @click="$emit('close')"
                        v-if="step === 1"
                        class="primary outline"
                      >
                        Close
                      </button>
                      <button v-else @click="step--" class="primary outline">
                        Back
                      </button>
                      <button
                        v-if="step === 2 && !deal.purchased"
                        @click="pay"
                        :disabled="!selectedCard || paying"
                        class="primary"
                      >
                        {{ paying ? "Processing..." : "Pay $49" }}
                      </button>
                      <button
                        v-else-if="step === 2 && !canUpdate"
                        :disabled="paying"
                        @click="$emit('close')"
                        class="primary"
                      >
                        Finish
                      </button>
                      <button
                        v-else-if="step === 3 && (!canSign || signed)"
                        @click="$emit('close')"
                        class="primary"
                      >
                        Close
                      </button>
                      <button
                        v-else-if="step === 3 && canSign"
                        :disabled="
                          hostSplitInvalid || !name || submitting || paying
                        "
                        @click="signContract"
                        class="primary"
                      >
                        {{ submitting ? "Submitting..." : "Accept and Sign" }}
                      </button>

                      <button
                        v-else-if="
                          step === 1 && contractHasOutstandingChanges()
                        "
                        :disabled="contractHasOutstandingChanges()"
                        @click="step++"
                        class="primary"
                      >
                        Continue
                      </button>
                      <button v-else @click="step++" class="primary">
                        Continue
                      </button>
                    </div>
                  </slot>
                </div>
              </div>
              <div key="step-2" v-if="step == 2" class="modal-container">
                <div class="modal-header">
                  <slot name="header">
                    <div @click="$emit('close')" class="icon">
                      <ModalCloseIcon :accent="false" />
                    </div>
                    <div class="title">
                      <h2>Payment</h2>
                    </div>
                  </slot>
                </div>

                <div class="modal-body">
                  <slot name="body">
                    <div class="step3">
                      <div class="price">
                        <h3 class="text">Price</h3>
                        <span class="number">$49</span>
                      </div>
                      <p class="info">
                        One-time payment to generate contracts on this song.
                      </p>
                      <p class="info2">
                        Either party can pay
                      </p>
                      <template v-if="!deal.purchased">
                        <div class="cards">
                          <div class="title">
                            <span class="saved-text"
                              >Select a payment method to continue</span
                            >
                            <div @click="addingCard = true" class="add-card">
                              <div class="plus-icon">
                                <img src="../../assets/icons/Plus-Accent.svg" />
                              </div>
                              Add Card
                            </div>
                          </div>
                          <div class="cards-list">
                            <div
                              v-for="pm in paymentMethods"
                              :key="pm.id"
                              @click="selectPaymentMethod(pm.id)"
                              class="card-container"
                            >
                              <Card
                                :card="pm.card"
                                :selectedCard="selectedCard"
                                :pmId="pm.id"
                              />
                            </div>
                          </div>
                        </div>
                      </template>
                      <template v-else>
                        <div class="paid">
                          <div class="check">
                            <img src="../../assets/icons/Check.svg" />
                          </div>
                          <div class="text">Paid successfully</div>
                        </div>
                        <span
                          v-if="deal.paid_by"
                          class="inline-flex items-center justify-start gap-1 pt-2 text-gray-600"
                          style="padding-left: 35px;"
                        >
                          By
                          <span
                            class="inline-flex items-center justify-start gap-1 px-2 py-1 border border-gray-200 cursor-default bg-gray-50 rounded-xl text-nvoko"
                          >
                            <span
                              class="flex items-center justify-center w-8 h-8 text-white bg-black border rounded-full select-none border-nvoko"
                              >{{ deal.paid_by.display_name | initials }}</span
                            >
                            {{ deal.paid_by.display_name }}
                          </span>
                          on
                          <span class="text-nvoko">
                            {{ deal.paid_by.paid_at | date }}
                          </span>
                        </span>
                      </template>
                    </div>
                  </slot>
                </div>

                <div class="modal-footer">
                  <slot name="footer">
                    <div class="buttons">
                      <button
                        @click="$emit('close')"
                        v-if="step === 1"
                        class="primary outline"
                      >
                        Close
                      </button>
                      <button v-else @click="step--" class="primary outline">
                        Back
                      </button>
                      <button
                        v-if="step === 2 && !deal.purchased"
                        @click="pay"
                        :disabled="!selectedCard || paying"
                        class="primary"
                      >
                        {{ paying ? "Processing..." : "Pay $49" }}
                      </button>
                      <button
                        v-else-if="step === 2 && !canUpdate"
                        :disabled="paying"
                        @click="$emit('close')"
                        class="primary"
                      >
                        Finish
                      </button>
                      <button
                        v-else-if="step === 3 && (!canSign || signed)"
                        @click="$emit('close')"
                        class="primary"
                      >
                        Close
                      </button>
                      <button
                        v-else-if="step === 3 && canSign"
                        :disabled="
                          hostSplitInvalid || !name || submitting || paying
                        "
                        @click="signContract"
                        class="primary"
                      >
                        {{ submitting ? "Submitting..." : "Accept and Sign" }}
                      </button>

                      <button
                        v-else-if="
                          step === 1 && contractHasOutstandingChanges()
                        "
                        :disabled="contractHasOutstandingChanges()"
                        @click="step++"
                        class="primary"
                      >
                        Continue
                      </button>
                      <button v-else @click="step++" class="primary">
                        Continue
                      </button>
                    </div>
                  </slot>
                </div>
              </div>
              <div key="step-3" v-if="step == 3" class="modal-container">
                <div class="modal-header">
                  <slot name="header">
                    <div @click="$emit('close')" class="icon">
                      <ModalCloseIcon :accent="false" />
                    </div>
                    <div class="title">
                      <h2>Sign Contract</h2>
                    </div>
                  </slot>
                </div>

                <div class="modal-body">
                  <slot name="body">
                    <div class="step4">
                      <div
                        v-if="contract.generated_text"
                        class="px-2 mb-4 overflow-hidden border-2 border-gray-200 rounded-lg terms"
                      >
                        <div
                          v-if="$apollo.queries.contract.loading"
                          class="text"
                        >
                          Loading...
                        </div>
                        <div
                          class="contract-preview"
                          @click="contractPreview = true"
                        >
                          <div
                            class="absolute inset-0 flex items-center justify-center bg-gradient-to-t from-white to-transparent"
                          >
                            <button class="shadow-lg primary">
                              Preview contract
                            </button>
                          </div>
                          <div
                            class="text preview-contract"
                            v-html="contract.generated_text"
                          />
                        </div>
                      </div>
                      <div class="cant-sign" v-if="!canSign">
                        Since you reviewed the deal &nbsp;
                        <b>{{
                          deal.party === "host"
                            ? dealSummary.guestName
                            : dealSummary.hostName
                        }}</b>
                        &nbsp; has to agree to terms by signing before you can
                        sign
                      </div>
                      <div v-else-if="signed"></div>
                      <template v-else>
                        <div class="details">
                          <div class="name">
                            <span>Full Name:</span>
                            <input
                              type="text"
                              v-model="name"
                              class="text-input"
                              placeholder="Enter your full name"
                            />
                          </div>
                          <div class="font">
                            <span>Change Font:</span>
                            <CustomSelect
                              :options="fonts"
                              default="Srisakdi"
                              @setValue="value => (font = value)"
                              class="font-selector"
                              noMargin
                              fonts
                            />
                          </div>
                        </div>
                        <div class="signature">
                          <div class="output">
                            <span class="name" :style="signatureStyle">
                              {{ name }}
                            </span>
                          </div>
                        </div>
                        <span class="info">
                          By selecting Accept and Sign, I agree that the
                          signature will be the electronic representation of my
                          signature for all purposes when I (or my agent) use
                          them on documents, including legally binding contracts
                          - just the same as a pen-and-paper signature or
                          initial.
                        </span>
                      </template>
                      <br />
                      <span class="invalid-split" v-if="hostSplitInvalid">
                        The current split is invalid.
                      </span>
                    </div>
                  </slot>
                </div>

                <div class="modal-footer">
                  <slot name="footer">
                    <div class="buttons">
                      <button
                        @click="$emit('close')"
                        v-if="step === 1"
                        class="primary outline"
                      >
                        Close
                      </button>
                      <button
                        v-else
                        @click="
                          () => {
                            if (deal.purchased) step = 1;
                            else step--;
                          }
                        "
                        class="primary outline"
                      >
                        Back
                      </button>
                      <button
                        v-if="step === 2 && !deal.purchased"
                        @click="pay"
                        :disabled="!selectedCard || paying"
                        class="primary"
                      >
                        {{ paying ? "Processing..." : "Pay $49" }}
                      </button>
                      <button
                        v-else-if="step === 2 && !canUpdate"
                        :disabled="paying"
                        @click="$emit('close')"
                        class="primary"
                      >
                        Finish
                      </button>
                      <button
                        v-else-if="step === 3 && (!canSign || signed)"
                        @click="$emit('close')"
                        class="primary"
                      >
                        Close
                      </button>
                      <button
                        v-else-if="step === 3 && canSign"
                        :disabled="
                          hostSplitInvalid || !name || submitting || paying
                        "
                        @click="signContract"
                        class="primary"
                      >
                        {{ submitting ? "Submitting..." : "Accept and Sign" }}
                      </button>

                      <button
                        v-else-if="
                          step === 1 && contractHasOutstandingChanges()
                        "
                        :disabled="contractHasOutstandingChanges()"
                        @click="step++"
                        class="primary"
                      >
                        Continue
                      </button>
                      <button v-else @click="step++" class="primary">
                        Continue
                      </button>
                    </div>
                  </slot>
                </div>
              </div>
            </transition>
          </div>
        </transition>
      </div>
    </div>
  </transition>
</template>

<script>
import axios from "axios";
import UpdateDealTerm from "@/components/modals/UpdateDealTerm.vue";
import PreviewContract from "@/components/modals/PreviewContract.vue";
import ModalCloseIcon from "@/assets/icons/Modal-Close.vue";
import Loader from "@/components/Loader.vue";
import PenIcon from "@/assets/icons/Pen.vue";
import CustomSelect from "@/components/Select.vue";
import FileLink from "@/components/FileLink.vue";
import AddCard from "@/components/modals/AddCard.vue";
import Card from "@/components/Card.vue";

import FETCH_CONTRACT_ANSWERS from "@/api/queries/FETCH_CONTRACT_ANSWERS.gql";
import GET_CONTRACT from "@/api/queries/GET_CONTRACT.gql";
import FETCH_CONTRACT_ANSWERS_UPDATE from "@/api/subscriptions/FETCH_CONTRACT_ANSWERS_UPDATE.gql";
import GET_CONTRACT_UPDATE from "@/api/subscriptions/GET_CONTRACT_UPDATE.gql";
import GET_SONGS from "@/api/queries/GET_SONGS.gql";
import GET_PAYMENT_METHODS from "@/api/queries/GET_PAYMENT_METHODS.gql";
import SIGN_CONTRACT from "@/api/mutations/SIGN_CONTRACT.gql";
import PAY_CONTRACTS from "@/api/mutations/PAY_CONTRACTS.gql";
import gql from "graphql-tag";

export default {
  name: "ReviewDeal",
  components: {
    ModalCloseIcon,
    UpdateDealTerm,
    Loader,
    PenIcon,
    CustomSelect,
    FileLink,
    PreviewContract,
    AddCard,
    Card
  },
  props: {
    deal: Object
  },
  data() {
    return {
      step: 1,
      updateQuestion: "",
      name: "",
      font: "Srisakdi",
      fonts: [
        { value: "Srisakdi", label: "Srisakdi" },
        { value: "Sacramento", label: "Sacramento" },
        { value: "Ojuju", label: "Ojuju" },
        { value: "Josefin Sans", label: "Josefin Sans" },
        { value: "Pacifico", label: "Pacifico" },
        { value: "Caveat", label: "Caveat" },
        { value: "Cookie", label: "Cookie" }
      ],
      submitting: false,
      paying: false,
      splitQuestionsBool: [
        "18919b87-bbfb-4647-bdc1-39f67f30d8ec",
        "0215a342-06f2-4a8e-9247-f04a775880de",
        "8bb2d9a8-f4ac-47d4-b9f6-49477645171f"
      ],
      splitQuestions: [
        "0144d576-fa84-4179-99c2-e28bd69000dc",
        "5dfe5592-5903-4bec-9f29-9022d1ed92f8",
        "06b6c5c5-2814-4df0-bf27-e54010740293"
      ],
      updateCounter: 0,
      canScrollLeft: false,
      canScrollRight: false,
      selectedCard: null,
      addingCard: false,
      lastContractUpdate: null,
      contractPreview: false
    };
  },
  computed: {
    user() {
      return this.$store.getters["account/getUser"];
    },
    parties() {
      const { artist, myArtist, party } = this.deal;
      const isHost = party === "host";
      return {
        host: isHost ? myArtist.name : artist.name,
        guest: isHost ? artist.name : myArtist.name
      };
    },
    canUpdate() {
      const { deal } = this;
      return deal.status !== "contract-ready";
    },
    canSign() {
      const { deal } = this;
      return deal.status !== `deal-updated-${deal.party}` && deal.purchased;
    },
    signed() {
      const { deal } = this;
      const restricted = ["contract-ready", `contract-signed-${deal.party}`];
      return restricted.includes(deal.status);
    },
    dealSummary() {
      const {
        getAnswer,
        deal: { party, artist, myArtist }
      } = this;

      const contribution = getAnswer("58c27cc4-b0eb-4ab0-b1ad-2fd7b7ca1b1a");
      const fee = getAnswer("5517025e-282e-4ead-bcad-224a65f9ca05");
      const recordingR = getAnswer("0144d576-fa84-4179-99c2-e28bd69000dc");
      const songwritingR = getAnswer("5dfe5592-5903-4bec-9f29-9022d1ed92f8");

      return {
        fee: fee || "$0",
        contribution: contribution || "None",
        recording_royalty: recordingR || "0%",
        songwriting_royalty: songwritingR || "0%",
        myParty: party === "host" ? "Host" : "Guest",
        otherParty: party === "host" ? "Guest" : "Host",
        hostName: party === "host" ? myArtist.name : artist.name,
        guestName: party === "guest" ? myArtist.name : artist.name
      };
    },
    signatureStyle() {
      const { font } = this;
      return {
        fontFamily: `${font}, cursive`
      };
    },
    hostSplitInvalid() {
      if (this.dealSummary.myParty !== "Host") return false;
      return !["recording", "publishing", "music_videos"].every(
        type =>
          this.deal.split.reduce((prev, el) => prev + el.percentage[type], 0) <=
          100
      );
    }
  },
  methods: {
    getAnswer(id) {
      return (this.answers.find(el => el.question.id === id) || {}).answer;
    },
    answerIsNotAcknowledged(answer) {
      return (
        this.getLastChange(answer) &&
        !this.getLastChange(answer)?.acknowledged_by?.includes(this.user.id)
      );
    },

    getLastChange(answer) {
      return answer?.changes?.slice(-1)[0];
    },
    contractHasOutstandingChanges() {
      return this.answers.filter(el => this.answerIsNotAcknowledged(el.answer))
        .length;
    },

    fillPartyNames(title) {
      const { host, guest } = this.parties;
      const hostFilled = title.replace(new RegExp("host", "ig"), host);
      return hostFilled.replace(new RegExp("guest", "ig"), guest);
    },
    formatAnswer({ answer, contract_question, changes }) {
      let resp = {};

      if (this.splitQuestionsBool.includes(contract_question.id)) return [];

      resp.question = {
        id: contract_question.id,
        title: this.fillPartyNames(contract_question.title)
      };

      if (contract_question.type === "file") {
        return [
          {
            ...resp,
            answer: {
              type: "file",
              file: { id: answer.value, name: "Open file" },
              changes: changes
            }
          }
        ];
      }

      if (contract_question.type === "multiple_choice") {
        changes = changes.map(el => ({
          ...el,
          answer_from: {
            value:
              contract_question.choices.find(
                choice => choice.value === el.answer_from.value
              )?.label || el.answer_from.value
          },
          answer_to: {
            value:
              contract_question.choices.find(
                choice => choice.value === el.answer_to.value
              )?.label || el.answer_to.value
          }
        }));

        const answerObject =
          contract_question.choices.find(
            choice => choice.value === answer.value
          ) || {};

        return [
          {
            ...resp,
            answer: { value: answerObject.label, changes: changes }
          }
        ];
      }

      if (contract_question.type === "multiple_choice_multiple") {
        changes = changes.map(el => ({
          ...el,
          answer_from: {
            value: el.answer_from.value
              .map(el => el.charAt(0).toUpperCase() + el.slice(1))
              .join(", ")
          },
          answer_to: {
            value: el.answer_to.value
              .map(el => el.charAt(0).toUpperCase() + el.slice(1))
              .join(", ")
          }
        }));

        return [
          {
            ...resp,
            answer: {
              value: answer.value
                .map(el => el.charAt(0).toUpperCase() + el.slice(1))
                .join(", "),
              changes: changes
            }
          }
        ];
      }

      const { prepend, append } = contract_question;

      return [
        {
          ...resp,
          answer: {
            value: (prepend || "") + answer.value + (append || ""),
            changes: changes
          }
        }
      ];
    },
    closeUpdateModal(refetch) {
      this.updateQuestion = "";
      if (refetch) {
        this.deal.status = `deal-updated-${this.deal.party}`;
      }
      this.$apollo.queries.answers.refetch();
    },
    selectPaymentMethod(index) {
      if (this.submitting.status) return;
      this.selectedCard = this.selectedCard === index ? null : index;
    },
    async pay() {
      this.paying = true;

      try {
        const {
          selectedCard: card,
          deal: { song_id: song }
        } = this;

        const {
          data: { stripe_pay_contracts }
        } = await this.$apollo.mutate({
          mutation: PAY_CONTRACTS,
          variables: { card, song },
          update: async (store, { data: { stripe_pay_contracts } }) => {
            if (!stripe_pay_contracts.success) {
              throw new Error("Payment failed");
            }

            try {
              const data = store.readQuery({
                query: GET_SONGS,
                variables: {
                  artistId: this.$store.getters["account/getArtistId"]
                }
              });

              if (data) {
                const songIndex = data.songs.findIndex(
                  el => el.id === this.deal.song_id
                );
                if (songIndex !== -1) {
                  data.songs[songIndex].purchases = [
                    {
                      __typename: "purchases",
                      user_id: this.$store.getters["account/getUserId"]
                    }
                  ];

                  store.writeQuery({
                    query: GET_SONGS,
                    variables: {
                      artistId: this.$store.getters["account/getArtistId"]
                    },
                    data
                  });
                }
              }

              this.deal.purchased = true;

              const song_id = this.deal.song_id;
              const artist_id = this.$store.getters["account/getArtistId"];
              await this.trackContractHistory(song_id, artist_id, "purchased");

              // Instead of reloading, update the component state
              this.step = 3; // Move to the next step
              this.$emit("payment-success");
            } catch (error) {
              console.error("Error updating cache after payment:", error);
              this.$emit("error", {
                message:
                  "Payment successful but error updating UI. Please refresh."
              });
            }
          }
        });

        if (!stripe_pay_contracts.success) {
          throw new Error("Payment failed");
        }
      } catch (error) {
        console.error("Payment error:", error);
        this.$emit("error", { message: "Payment failed. Please try again." });
      } finally {
        this.paying = false;
      }
    },
    async signContract() {
      this.submitting = true;

      const {
        name,
        font,
        contract: { id },
        user: { id: user_id },
        deal: {
          status: oldStatus,
          id: deal_id,
          party,
          artist: { id: toID },
          myArtist: { id: fromID, name: myArtistName }
        }
      } = this;

      const message = {
        deal_id,
        artist_id_to: toID,
        artist_id_from: fromID,
        system_message: {
          recipient: `${myArtistName} has signed the contract`,
          sender: "You have signed the contract"
        }
      };

      try {
        const { data } = await axios.get(
          "https://nvoko-hasura-remote-git-contract-signed-nvoko.vercel.app/api/info"
        );

        const signature = {
          value: { name, font },
          type: "contract",
          contract_id: id,
          artist_id: fromID,
          user_id,
          party,
          ...data
        };

        const status = oldStatus.includes("contract-signed")
          ? "contract-ready"
          : `contract-signed-${party}`;

        await this.$apollo.mutate({
          mutation: SIGN_CONTRACT,
          variables: {
            deal_id,
            status,
            message,
            signature
          },
          update: async (
            store,
            { data: { update_deals_by_pk, insert_contract_signatures_one } }
          ) => {
            try {
              const songsObject = store.readQuery({
                query: GET_SONGS,
                variables: {
                  artistId: this.$store.getters["account/getArtistId"]
                }
              });

              if (songsObject) {
                const song = songsObject.songs.findIndex(
                  el => el.id === this.deal.song_id
                );
                const index = songsObject.songs[
                  song
                ].splits[0].split_details.findIndex(
                  el => el.deal_id === this.deal.id
                );

                songsObject.songs[song].splits[0].split_details[index].deal = {
                  ...songsObject.songs[song].splits[0].split_details[index]
                    .deal,
                  ...update_deals_by_pk
                };

                store.writeQuery({
                  query: GET_SONGS,
                  variables: {
                    artistId: this.$store.getters["account/getArtistId"]
                  },
                  data: songsObject
                });
              }

              const contractObject = store.readQuery({
                query: GET_CONTRACT,
                variables: {
                  deal_id: this.deal.id
                }
              });

              if (contractObject) {
                const sigIndex = contractObject.contracts[0].signatures.findIndex(
                  el => el.id === insert_contract_signatures_one.id
                );

                if (sigIndex >= 0) {
                  contractObject.contracts[0].signatures[
                    sigIndex
                  ] = insert_contract_signatures_one;
                } else {
                  contractObject.contracts[0].signatures = [
                    ...contractObject.contracts[0].signatures,
                    insert_contract_signatures_one
                  ];
                }

                store.writeQuery({
                  query: GET_CONTRACT,
                  variables: {
                    deal_id: this.deal.id
                  },
                  data: contractObject
                });
              }

              const song_id = this.deal.song_id;
              const artist_id = this.$store.getters["account/getArtistId"];
              await this.trackContractHistory(song_id, artist_id, "signed");

              this.$emit("close");
            } catch (error) {
              console.error("Error updating cache:", error);
              // Instead of reloading, we'll emit an error event that parent can handle
              this.$emit("error", error);
            }
          }
        });
      } catch (error) {
        console.error("Error signing contract:", error);
        this.$emit("error", error);
      } finally {
        this.submitting = false;
      }
    },
    async acknowledgeChange(answer, refetch = true) {
      const lastChange = this.getLastChange(answer);
      if (!lastChange || lastChange?.length < 1) return;
      const acknowledged_by = new Set(lastChange.acknowledged_by);
      acknowledged_by.add(this.user.id);

      const ACKNOWLEDGE_CHANGE = gql`
        mutation acknowledge_change($id: Int, $acknowledged_by: [String]) {
          update_contract_answer_changes(
            where: { id: { _eq: $id } }
            _set: { acknowledged_by: $acknowledged_by }
          ) {
            affected_rows
          }
        }
      `;
      await this.$apollo.mutate({
        mutation: ACKNOWLEDGE_CHANGE,
        variables: {
          id: lastChange.id,
          acknowledged_by: [...acknowledged_by]
        }
      });

      if (refetch) {
        const song_id = this.deal.song_id;
        const artist_id = this.$store.getters["account/getArtistId"];

        await this.trackContractHistory(
          song_id,
          artist_id,
          "acknowledgedTermChanges"
        );

        this.$apollo.queries.answers.refetch();
      }
    },
    async acknowledgeAllChanges() {
      await Promise.all(
        this.answers.map(answer => this.acknowledgeChange(answer.answer, false))
      );
      const song_id = this.deal.song_id;
      const artist_id = this.$store.getters["account/getArtistId"];
      await this.trackContractHistory(
        song_id,
        artist_id,
        "acknowledgedTermChanges"
      );
      this.$apollo.queries.answers.refetch();
    }
  },
  apollo: {
    answers: {
      query: FETCH_CONTRACT_ANSWERS,
      variables() {
        return { deal_id: this.deal.id };
      },
      update({ contract_answers }) {
        const { formatAnswer: format } = this;
        return contract_answers.flatMap(answer => format(answer));
      },
      pollInterval: 5000,
      error(error) {
        console.error("Error fetching answers:", error);
        this.$emit("error", { message: "Error loading contract answers" });
      }
    },
    paymentMethods: {
      query: GET_PAYMENT_METHODS,
      update: ({ stripe_get_payment_methods }) => stripe_get_payment_methods,
      error(error) {
        console.error("Error fetching payment methods:", error);
        this.$emit("error", { message: "Error loading payment methods" });
      }
    },
    contract: {
      query: GET_CONTRACT,
      variables() {
        return { deal_id: this.deal.id };
      },
      update: ({ contracts }) => {
        if (!contracts || contracts.length === 0) {
          return null;
        }
        return contracts[0];
      },
      pollInterval: 5000,
      error(error) {
        console.error("Error fetching contract:", error);
        this.$emit("error", { message: "Error loading contract" });
      }
    }
  },
  watch: {
    contract: {
      handler(newContract) {
        if (!newContract) return;

        if (this.lastContractUpdate !== JSON.stringify(newContract)) {
          console.log("Contract changed:", newContract);
          this.lastContractUpdate = JSON.stringify(newContract);
        }
      },
      deep: true
    },
    step(newStep, oldStep) {
      console.log(`Step changed from ${oldStep} to ${newStep}`);
    }
  },
  filters: {
    initials(name) {
      return name
        ?.split(" ")
        .slice(0, 2)
        .map(el => el.charAt(0).toUpperCase())
        .join("");
    },
    cardExpiry(data) {
      const month = data[0] > 9 ? `${data[0]}` : `0${data[0]}`;
      const yearTwoDig = data[1] % 100;
      const year = yearTwoDig > 9 ? `${yearTwoDig}` : `0${yearTwoDig}`;
      return month + "/" + year;
    },
    date(timestamp) {
      return new Date(timestamp).toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric"
      });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/styles/_mixins.scss";
@import url("https://fonts.googleapis.com/css2?family=Srisakdi&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Sacramento&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Ojuju&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Pacifico&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Caveat&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cookie&display=swap");

.modal-container {
  width: 755px;
  height: fit-content;
  max-height: calc(100vh - 40px);
  background: #ffffff;
  box-shadow: -1px 0px 48px rgba(0, 0, 0, 0.0812937);
  border-radius: 15px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.modal-header {
  flex-wrap: wrap;
  justify-content: flex-start;
  padding-bottom: 16px;

  .icon {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .title {
    display: flex;
    padding: 0 8px;
    width: 100%;
    justify-content: center;

    h2 {
      display: flex;
      align-items: center;
      font-weight: bold;
      font-size: 24px;
      line-height: 24px;
      text-align: center;
      letter-spacing: -1.025px;
      color: $text;
    }
  }

  .progress {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 25px;
    padding: 0;

    .step {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 1;

      &.active {
        .number {
          background: $accent;

          .text {
            color: $white;
          }
        }

        .text {
          font-weight: 500;
          color: $text;

          &.active {
            font-weight: 700;
          }
        }
      }

      .number {
        @include background-opacity($text-additional);
        width: 60px;
        height: 60px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 16px;

        .text {
          font-weight: 500;
          font-size: 24px;
          line-height: 24px;
          letter-spacing: -0.22px;
          color: $text-additional;
        }
      }

      .text {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.22px;
        color: $text-additional;
        text-align: center;
      }
    }

    .separator {
      margin-top: 18px;
      flex: 1;
      display: flex;
      justify-content: center;
    }
  }
}

.modal-body {
  padding: 0 40px;
  padding-top: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .step1 {
    margin: 20px 0;

    .details {
      .detail {
        width: 100%;
        display: flex;

        span {
          width: 50%;
          color: $text-additional;
          padding: 0 6px;
          margin-bottom: 16px;
          text-align: left;
          white-space: nowrap;
          line-height: 14px;
        }

        .title {
          color: $text;
          text-align: right;
          font-weight: 500;
        }
      }
    }
  }

  .step2 {
    margin: 20px 0;
    padding: 0 50px;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: $accent rgba(#cdcccc, 0.3);

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      @include background-opacity(#cdcccc, 0.3);
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $accent;
      border-radius: 5px;
    }

    .answers {
      .answer {
        margin-bottom: 32px;

        .question-title {
          margin-bottom: 8px;
          display: flex;
          align-items: center;

          .text {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.22px;
            color: $text;
          }

          .icon {
            margin-left: 8px;
            display: flex;
            align-items: center;
          }
        }

        .file-link {
          width: 65%;
        }

        .answer-value {
          font-style: normal;
          font-weight: 300;
          line-height: 14px;
          letter-spacing: -0.22px;
          color: $text;
        }
      }
    }
  }

  .step3 {
    padding: 20px 24px;

    .price {
      display: flex;
      align-items: baseline;
      margin-bottom: 8px;

      .text {
        font-weight: 500;
        font-size: 18px;
        line-height: 18px;
        letter-spacing: -0.666667px;
        color: $text;
      }

      .number {
        margin-left: 8px;
        font-weight: 500;
        font-size: 14px;
        line-height: 14px;
        letter-spacing: 0.07px;
        color: $accent;
      }
    }

    .info {
      font-weight: 500;
      font-size: 12px;
      letter-spacing: -0.666667px;
      color: $text;
    }

    .info2 {
      font-weight: 500;
      font-size: 12px;
      letter-spacing: -0.666667px;
      color: $accent;
    }

    .cards {
      margin-top: 15px;

      .title {
        display: flex;
        align-items: center;
        font-size: 10px;
        line-height: 24px;
        letter-spacing: -0.22px;
        color: black;

        .saved-text {
          flex: 1;
        }

        .add-card {
          display: flex;
          align-items: center;
          cursor: pointer;

          .plus-icon {
            margin-right: 4px;
          }
        }
      }

      .cards-list {
        display: flex;
        margin-top: 8px;
        padding-bottom: 5px;
        overflow-x: auto;
        scrollbar-width: thin;
        scrollbar-color: $accent rgba(#cdcccc, 0.3);

        &::-webkit-scrollbar {
          /* height of the entire scrollbar */
          height: 6px;
        }

        &::-webkit-scrollbar-track {
          /* color of the tracking area */
          @include background-opacity(#cdcccc, 0.3);
          border-radius: 5px;
        }

        &::-webkit-scrollbar-thumb {
          /* color of the scroll thumb */
          background-color: $accent;
          /* roundness of the scroll thumb */
          border-radius: 5px;
        }

        .card-container {
          margin-right: 16px;
        }
      }
    }

    .pay {
      margin-top: 15px;
    }

    .paid {
      display: flex;
      align-items: center;
      margin-top: 25px;

      .check {
        width: 27px;
        height: 27px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: $accent;
        overflow: hidden;
        border-radius: 50%;

        img {
          object-fit: cover;
          width: 16px;
        }
      }

      .text {
        font-size: 1.1rem;
        font-weight: 500;
        margin-left: 8px;
      }
    }
  }

  .step4 {
    padding: 0 30px;

    .preview {
      display: flex;
      align-items: center;
      color: $accent;
      padding: 1.5rem 0.2rem;
      cursor: pointer;
      width: fit-content;

      img {
        height: 1.5rem;
        width: 1.5rem;
        transform: rotate(135deg);
      }

      span {
        margin-bottom: 2px;
        margin-left: 5px;
        font-size: 1.1rem;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
      }
    }

    .cant-sign {
      color: $text;
      padding: 0.5rem 0.2rem;
    }

    .details {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.7rem;

      .name {
        display: flex;
        align-items: center;

        span {
          margin-right: 0.5rem;
        }

        input {
          height: 40px;
          width: 13rem;
          color: #3b3b3b;
        }
      }

      .font {
        display: flex;
        align-items: center;

        span {
          margin-right: 0.5rem;
        }

        .font-selector {
          width: 11rem;
        }
      }
    }

    .signature {
      height: 8rem;
      background: $gray;
      margin-bottom: 8px;
      padding: 16px;
      display: flex;
      flex-direction: column;

      .output {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        border-bottom: 1px solid $text-additional;
        margin: 0 24px;
        margin-bottom: 16px;

        .name {
          font-size: 45px;
          text-align: center;
          font-style: italic;
          letter-spacing: -0.22px;
          color: $text;
        }
      }
    }

    .info {
      @include details-light();
      width: 616px;
      margin-bottom: 1.5rem;
    }

    .invalid-split {
      @include details-light();
      color: red;
      width: 616px;
      display: flex;
      justify-content: flex-end;
      margin-bottom: 3px;
    }
  }
}

.modal-footer {
  padding: 7px 40px 20px;

  .buttons {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding: 0 40px;

    button {
      width: 136px;
      height: 44px;
      margin-left: 32px;
      font-weight: 300;
      padding: 0 8px;
    }
  }
}

.contract-preview {
  max-height: 300px;
  width: 100%;
  overflow: hidden;
  cursor: pointer;
  position: relative;

  .text {
    flex: 1;
    width: 100%;
    height: 100%;
    overflow-y: hidden;
    margin-bottom: 1rem;
    scrollbar-width: thin;
    scrollbar-color: $accent rgba(#cdcccc, 0.3);

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      @include background-opacity(#cdcccc, 0.3);
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $accent;
      border-radius: 5px;
    }

    &::v-deep {
      p,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        margin: 15px 5px;
      }
    }
  }
}

.modal-enter {
  opacity: 0;
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.modal-leave {
  opacity: 0;
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
}

.modal-leave-active {
  opacity: 0;
  transition: all 0.1s ease-in-out;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
