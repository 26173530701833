<template>
  <div :class="{ 'no-scroll': withSidebar, 'noise-texture': withSidebar }">
    <div id="app" :class="{ 'with-sidebar': withSidebar }">
      <Sidebar v-if="withSidebar" class="main-sidebar" />
      <router-view class="page-container" />
    </div>
    <tooltip />
  </div>
</template>

<script>
import Sidebar from "./components/Sidebar.vue";

import USER_DETAILS_SUBSCRIPTION from "@/api/subscriptions/USER_DETAILS_SUBSCRIPTION.gql";

export default {
  data() {
    return {
      showModal: false
    };
  },
  name: "App",
  components: { Sidebar },
  watch: {
    withSidebar() {
      this.resetSocketConnection();
    }
  },
  computed: {
    withSidebar() {
      const routes = [
        "/",
        "/home",
        "/signup",
        "/signup/verification",
        "/onboarding",
        "/reset-password",
        "/waitlisted"
      ];
      return !routes.includes(this.$route.path);
    }
  },
  apollo: {
    $subscribe: {
      details: {
        query: USER_DETAILS_SUBSCRIPTION,
        variables() {
          return { id: this.$store.getters["account/getUserId"] };
        },
        result(data) {
          const {
            charges_enabled,
            payouts_enabled,
            subscriptions,
            company_users
          } = data.data.users_by_pk;

          this.$store.commit("account/updateUser", {
            charges_enabled,
            payouts_enabled,
            subscription: subscriptions[0],
            company_users
          });
        },
        skip() {
          return !this.withSidebar;
        }
      }
    }
  }
};
</script>

<style lang="scss">
body {
  background: #54001d;
}

.noise-texture {
  position: relative;

  &::after {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 200 200' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noise'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='2.5' numOctaves='5' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%' height='100%' filter='url(%23noise)' opacity='0.2'/%3E%3C/svg%3E");
    pointer-events: none;
    opacity: 0.5;
    z-index: 1;
  }
}

.no-scroll {
  height: 100%;
  overflow: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
  padding: 0 20px;

  &::before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to bottom right,
      rgba(0, 0, 0, 0.6),
      rgba(0, 0, 0, 0.9)
    );

    pointer-events: none;
    z-index: -1;
    opacity: 1;
  }
}

#app {
  font-family: sofia-pro, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $text;
  display: flex;

  .main-sidebar {
    min-width: 279px;
    height: 100%;
  }

  .page-container {
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
    background-color: $white;
    border-radius: 20px;
    position: relative;

    .content {
      padding-bottom: 32px;
    }
  }
}

.with-sidebar {
  max-width: 1600px;
  border-radius: 20px;
  height: calc(100vh - 50px);

  overflow: hidden;
  margin: 25px auto;
  gap: 25px;
}
</style>
