<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <Loader
          v-if="$apollo.queries.contract_questions.loading"
          color="#FF035A"
          size="50"
        />
        <div v-else class="modal-container">
          <div class="modal-header">
            <slot name="header">
              <div @click="$emit('close')" class="icon">
                <ModalCloseIcon :accent="false" />
              </div>
            </slot>
            <div class="categories-tabs">
              <button
                class="scroll-button left"
                @click="scrollCategories('left')"
                v-show="canScrollLeft"
              >
                <span>‹</span>
              </button>
              <div class="scroll-container " ref="scrollContainer">
                <div
                  v-for="category in uniqueCategories"
                  :key="category"
                  :class="[
                    'category-tab',
                    { active: currentCategory === category }
                  ]"
                  @click="selectCategory(category)"
                  :ref="
                    'category-' + category.replace(/\s+/g, '-').toLowerCase()
                  "
                >
                  {{ category }}
                  <span
                    v-if="hasUnansweredQuestions(category)"
                    class="unanswered-indicator"
                  />
                </div>
              </div>
              <button
                class="scroll-button right"
                @click="scrollCategories('right')"
                v-show="canScrollRight"
              >
                <span>›</span>
              </button>
            </div>
          </div>

          <div class="modal-body">
            <slot name="body">
              <div class="questions-container py-4">
                <DealTerm
                  v-for="(el, index) in currentCategoryQuestions"
                  :key="el.id"
                  v-model="currentCategoryQuestions[index]"
                  :parties="parties"
                  @update:answer="() => updateCounter++"
                />
              </div>
              <div class="navigation-buttons">
                <button
                  v-if="!isFirstCategory"
                  @click="goToPrevCategory"
                  class="secondary"
                >
                  Previous
                </button>
                <button
                  v-if="!isLastCategory"
                  @click="goToNextCategory"
                  :disabled="hasUnansweredQuestions(currentCategory)"
                  class="primary"
                >
                  Next
                </button>
                <button
                  v-else
                  :disabled="!valid || submitting"
                  @click="createDeal"
                  class="primary"
                >
                  {{ submitting ? "Submitting..." : "Create Deal" }}
                </button>
              </div>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import DealTerm from "@/components/DealTerm.vue";
import ModalCloseIcon from "@/assets/icons/Modal-Close.vue";
import Loader from "@/components/Loader.vue";

import GET_SONGS from "@/api/queries/GET_SONGS.gql";
import GET_CONTRACT_QUESTIONS from "@/api/queries/GET_CONTRACT_QUESTIONS.gql";
import CREATE_CONTRACT from "@/api/mutations/CREATE_CONTRACT.gql";

export default {
  name: "CreateDeal",
  components: {
    ModalCloseIcon,
    Loader,
    DealTerm
  },
  data() {
    return {
      currentCategory: null,
      questions: [],
      submitting: false,
      splitQuestionsBool: [
        "18919b87-bbfb-4647-bdc1-39f67f30d8ec",
        "0215a342-06f2-4a8e-9247-f04a775880de",
        "8bb2d9a8-f4ac-47d4-b9f6-49477645171f"
      ],
      splitQuestions: [
        "0144d576-fa84-4179-99c2-e28bd69000dc",
        "5dfe5592-5903-4bec-9f29-9022d1ed92f8",
        "06b6c5c5-2814-4df0-bf27-e54010740293"
      ],
      updateCounter: 0,
      canScrollLeft: false,
      canScrollRight: false
    };
  },
  props: {
    deal: Object
  },
  watch: {
    contract_questions: {
      handler(questions) {
        this.questions = this.formatQuestions(questions);
        if (!this.currentCategory && this.uniqueCategories.length > 0) {
          this.currentCategory = this.uniqueCategories[0];
        }
      },
      immediate: true
    },
    comparisonAnswers: {
      handler(answers, oldVal) {
        const { splitQuestions, splitQuestionsBool, questions } = this;
        splitQuestions.forEach((el, i) => {
          const val = answers[el] > 0;
          if (
            !["undefined", ""].includes(typeof answers[el]) &&
            answers[splitQuestionsBool[i]] !== val
          ) {
            const question = questions.find(
              el => el.id === splitQuestionsBool[i]
            );
            question.answer = question.choices.find(el => el.value === val);
          }
        });

        let changedQuestionId = this.findChangedQuestionId(answers, oldVal);
        if (changedQuestionId) {
          this.resetDependentAnswers(changedQuestionId);
        }
      },
      deep: true
    }
  },
  computed: {
    parties() {
      const { artist, myArtist, party } = this.deal;
      const isHost = party === "host";
      return {
        host: isHost ? myArtist.name : artist.name,
        guest: isHost ? artist.name : myArtist.name
      };
    },
    currentQuestion() {
      const { validQuestions, step } = this;
      return validQuestions.length ? validQuestions[step - 1] : {};
    },
    stepInvalid() {
      const { isStepValid, currentQuestion } = this;
      return !isStepValid(currentQuestion);
    },
    valid() {
      return this.validQuestions.every(el => this.isStepValid(el));
    },
    validQuestions() {
      const { questions, runChecks, splitQuestionsBool } = this;
      const q = questions.flatMap(el => {
        if (splitQuestionsBool.includes(el.id)) return [];
        return runChecks(el.conditions) ? [el] : [];
      });
      return q;
    },
    comparisonAnswers() {
      let answers = {};
      this.parseAnswers(true).forEach(({ question_id, answer }) => {
        answers[question_id] = answer.value;
      });

      return answers;
    },
    uniqueCategories() {
      // Get all categories first
      const allCategories = [...new Set(this.questions.map(q => q.category))];

      // Filter categories that have at least one potentially valid question
      return allCategories
        .filter(category => {
          const categoryQuestions = this.questions.filter(
            q => q.category === category
          );
          // A category is valid if at least one of its questions could potentially be shown
          return categoryQuestions.some(q => {
            // If no conditions or only party condition matches current party, keep it
            if (
              !q.conditions ||
              typeof q.conditions === "string" ||
              Object.keys(q.conditions).length === 0
            )
              return true;
            if (q.conditions.party && q.conditions.party !== this.deal.party)
              return false;
            return true;
          });
        })
        .sort((a, b) => {
          const aQuestion = this.questions.find(q => q.category === a);
          const bQuestion = this.questions.find(q => q.category === b);
          return (aQuestion?.priority || 0) - (bQuestion?.priority || 0);
        });
    },
    isFirstCategory() {
      return this.uniqueCategories.indexOf(this.currentCategory) === 0;
    },
    isLastCategory() {
      return (
        this.uniqueCategories.indexOf(this.currentCategory) ===
        this.uniqueCategories.length - 1
      );
    },
    currentCategoryQuestions() {
      return this.validQuestions.filter(
        q => q.category === this.currentCategory
      );
    }
  },
  methods: {
    resetDependentAnswers(changedQuestionId) {
      this.questions.forEach(question => {
        if (
          question.conditions?.checks?.some(
            check => check.question_id === changedQuestionId
          )
        ) {
          // Assuming a simple structure where resetting simply means setting the answer to null or an initial state
          // You might need a more complex reset logic based on your application's needs
          if (question.answer.value) {
            question.answer = {}; // Resetting the answer, adjust according to your data structure
          }
        }
      });
    },
    findChangedQuestionId(newVal, oldVal) {
      for (const questionId in newVal) {
        const newAnswer = newVal[questionId];
        const oldAnswer = oldVal[questionId];

        // Check if both are arrays and compare their contents
        if (Array.isArray(newAnswer) && Array.isArray(oldAnswer)) {
          if (
            newAnswer.length !== oldAnswer.length ||
            !newAnswer.every((val, index) => val === oldAnswer[index])
          ) {
            return questionId;
          }
        } else if (newAnswer !== oldAnswer) {
          // Direct comparison for non-array values
          return questionId;
        }
      }
      return null; // In case there's no change detected
    },
    runChecks(conditions) {
      // Handle empty or invalid conditions
      if (
        !conditions ||
        typeof conditions === "string" ||
        Object.keys(conditions).length === 0
      ) {
        return true;
      }

      try {
        conditions =
          typeof conditions === "string" ? JSON.parse(conditions) : conditions;
      } catch (e) {
        return true;
      }

      const { comparisonAnswers, deal } = this;

      // Check party condition first
      if (conditions.party && conditions.party !== deal.party) {
        return false;
      }

      // If no checks, and party matches (or no party specified), show the question
      if (!conditions.checks) {
        return true;
      }

      const compare = ({ question_id, value, _or, _and, id }) => {
        // Special case for self-referential conditions
        if (id === question_id) return true;

        if (_or) return _or.some(el => compare(el));
        if (_and) return _and.every(el => compare(el));

        // If the question_id doesn't exist in answers yet, treat it as false
        if (typeof comparisonAnswers[question_id] === "undefined") {
          return false;
        }

        const answerValue = comparisonAnswers[question_id];
        const compareValue = Object.values(value)[0];
        const compareOperator = Object.keys(value)[0];

        switch (compareOperator) {
          case "_eq":
            return answerValue === compareValue;
          case "_gte":
            return Number(answerValue) >= Number(compareValue);
          case "_lte":
            return Number(answerValue) <= Number(compareValue);
          case "_gt":
            return Number(answerValue) > Number(compareValue);
          case "_lt":
            return Number(answerValue) < Number(compareValue);
          case "_neq":
            return answerValue !== compareValue;
          case "_contains":
            if (Array.isArray(answerValue)) {
              return answerValue.includes(compareValue);
            }
            if (typeof answerValue === "string") {
              return answerValue.includes(compareValue);
            }
            return String(answerValue).includes(String(compareValue));
          default:
            return false;
        }
      };

      // Each check in the array must pass
      return conditions.checks.every(check => compare(check));
    },

    fillPartyNames(question) {
      const resp = question;
      const { host, guest } = this.parties;
      const hostFilled = question.title.replace(new RegExp("host", "ig"), host);
      resp.title = hostFilled.replace(new RegExp("guest", "ig"), guest);
      return resp;
    },

    formatQuestions(questions) {
      const { fillPartyNames } = this;
      return questions.map(question => {
        const formattedQuestion = {
          ...fillPartyNames(question),
          answer: question.type === "multiple_choice_multiple" ? [] : {}
        };

        if (question.choices) {
          formattedQuestion.choices = question.choices.map(choice => {
            if (choice.sub_id) {
              const subQuestion = questions.find(q => q.id === choice.sub_id);
              if (subQuestion) {
                choice.sub = this.formatQuestions([subQuestion])[0];
              }
            }
            return choice;
          });
        }

        return formattedQuestion;
      });
    },

    isStepValid({ answer = {}, type, id }) {
      const { isStepValid, comparisonAnswers } = this;

      const splitQuestions = [
        "0144d576-fa84-4179-99c2-e28bd69000dc",
        "5dfe5592-5903-4bec-9f29-9022d1ed92f8",
        "06b6c5c5-2814-4df0-bf27-e54010740293"
      ];

      if (splitQuestions.includes(id) && Number(answer.value) > 100) {
        return false;
      } else if (
        id === "78b2ee02-5358-4df5-a1b2-3467b204ecc8" &&
        !answer.find(el => el.value === "producing")
      ) {
        return false;
      } else if (
        type === "multiple_choice_multiple" &&
        answer.length &&
        answer.every(el => !!el.value)
      ) {
        return true;
      } else if (
        ["null", "undefined"].includes(typeof answer.value) ||
        (type !== "multiple_choice" && !answer.value)
      ) {
        return false;
      } else if (
        id === "6c31c155-c6d2-490b-9125-34fb856a8724" &&
        answer.value &&
        !comparisonAnswers["5517025e-282e-4ead-bcad-224a65f9ca05"]
        // !comparisonAnswers["18919b87-bbfb-4647-bdc1-39f67f30d8ec"]
      ) {
        return false;
      } else if (answer.sub && !isStepValid(answer.sub)) {
        return false;
      }

      return true;
    },

    parseAnswers(isGetAll) {
      const { questions } = this;
      let answers = [];

      const getAnswers = ({ id, type, answer }) => {
        let response =
          isGetAll ||
          answer.length ||
          typeof answer.value === "boolean" ||
          typeof answer.value === "number" ||
          (answer.value || []).length
            ? [
                {
                  question_id: id,
                  answer: {
                    value:
                      type !== "multiple_choice_multiple"
                        ? answer.value
                        : answer.map(el => el.value)
                  }
                }
              ]
            : [];
        if (answer.sub) {
          const subAnswers = getAnswers(answer.sub);
          response = [...response, ...subAnswers];
        }
        return answers.find(answer => answer.question_id === id)
          ? []
          : response;
      };

      questions.forEach(question => {
        const questionAnswers = getAnswers(question);
        answers = [...answers, ...questionAnswers];
      });

      return answers;
    },

    calculateNewSplit(fromID, deal_id, song_id, split, answers) {
      const { splitQuestions } = this;
      let newSplit = split;

      let index = newSplit.findIndex(el => el.deal_id === deal_id);
      let newPercentage = newSplit[index].percentage;

      answers.forEach(({ question_id, answer: { value } }) => {
        if (splitQuestions.includes(question_id)) {
          newPercentage[
            ["recording", "publishing", "music_videos"][
              splitQuestions.findIndex(el => el === question_id)
            ]
          ] = Number(value);
        }
      });

      newSplit[index].percentage = newPercentage;

      return {
        song_id,
        artist_id_proposer: fromID,
        split_details: { data: newSplit }
      };
    },

    getDealUpdates(answers, party) {
      let resp = { status: `deal-updated-${party}` };
      const services = "58c27cc4-b0eb-4ab0-b1ad-2fd7b7ca1b1a";
      const involvesFee = "6c31c155-c6d2-490b-9125-34fb856a8724";
      const fee = "5517025e-282e-4ead-bcad-224a65f9ca05";
      answers.forEach(({ question_id, answer: { value } }) => {
        if (question_id === services) resp.services = value;
        if (question_id === fee) resp.fee = parseFloat(value);
        if (question_id === involvesFee) {
          if (value) resp.fee_status = "pending";
          else {
            resp.fee_status = null;
            resp.fee = 0;
          }
        }
      });
      return resp;
    },

    async createDeal() {
      this.submitting = true;

      const {
        parseAnswers,
        calculateNewSplit,
        getDealUpdates,
        deal: {
          party,
          split: oldSplit,
          song_id,
          id: deal_id,
          artist: { id: toID },
          myArtist: { id: fromID, name: myArtistName }
        }
      } = this;

      const answers = parseAnswers();

      const split = calculateNewSplit(
        fromID,
        deal_id,
        song_id,
        oldSplit,
        answers
      );

      const dealUpdates = getDealUpdates(answers, party);

      const message = {
        deal_id,
        artist_id_to: toID,
        artist_id_from: fromID,
        system_message: {
          recipient: `${myArtistName} has answered contract questions`,
          sender: "You have answered contract questions"
        }
      };

      await this.$apollo.mutate({
        mutation: CREATE_CONTRACT,
        variables: { deal_id, answers, message, dealUpdates, split },
        update: async (store, { data: { insert_splits_one } }) => {
          const id = this.$store.getters["account/getArtistId"];

          let data = null;

          await this.trackContractHistory(song_id, fromID, "createdContract");

          await this.trackContractHistory(song_id, toID, "waitingForApproval");

          try {
            data = await store.readQuery({
              query: GET_SONGS,
              variables: {
                artistId: id
              }
            });
          } catch (error) {
            location.reload();
          }

          if (!data) return;

          const index = data.songs.findIndex(el => el.id === song_id);

          data.songs[index] = {
            ...data.songs[index],
            splits: [insert_splits_one]
          };

          await store.writeQuery({
            query: GET_SONGS,
            variables: {
              artistId: id
            },
            data
          });

          this.submitting = false;

          this.$emit("close");
        }
      });

      this.submitting = false;
    },

    hasUnansweredQuestions(category) {
      // Only check questions that are valid based on current conditions
      return this.validQuestions.some(q => {
        if (q.category !== category) return false;

        // For multiple choice multiple, check if there's at least one selection
        if (q.type === "multiple_choice_multiple") {
          return !Array.isArray(q.answer) || q.answer.length === 0;
        }

        // For other types, check if there's a value
        return (
          !q.answer ||
          typeof q.answer.value === "undefined" ||
          q.answer.value === null ||
          q.answer.value === ""
        );
      });
    },

    goToNextCategory() {
      const currentIndex = this.uniqueCategories.indexOf(this.currentCategory);
      if (currentIndex < this.uniqueCategories.length - 1) {
        this.currentCategory = this.uniqueCategories[currentIndex + 1];
      }
    },

    goToPrevCategory() {
      const currentIndex = this.uniqueCategories.indexOf(this.currentCategory);
      if (currentIndex > 0) {
        this.currentCategory = this.uniqueCategories[currentIndex - 1];
      }
    },

    scrollCategories(direction) {
      const container = this.$refs.scrollContainer;
      const scrollAmount = 200; // Adjust this value to control scroll distance

      if (direction === "left") {
        container.scrollLeft -= scrollAmount;
      } else {
        container.scrollLeft += scrollAmount;
      }
    },

    checkScrollButtons() {
      const container = this.$refs.scrollContainer;
      if (container) {
        this.canScrollLeft = container.scrollLeft > 1;
        this.canScrollRight =
          Math.ceil(container.scrollLeft + container.clientWidth) <
          container.scrollWidth - 1;
      }
    },

    selectCategory(category) {
      this.currentCategory = category;
      this.$nextTick(() => {
        const tabRef = this.$refs[
          `category-${category.replace(/\s+/g, "-").toLowerCase()}`
        ][0];
        if (tabRef) {
          const container = this.$refs.scrollContainer;
          const tabRect = tabRef.getBoundingClientRect();
          const containerRect = container.getBoundingClientRect();

          // Check if the tab is partially or fully outside the visible area
          if (
            tabRect.right > containerRect.right ||
            tabRect.left < containerRect.left
          ) {
            tabRef.scrollIntoView({
              behavior: "smooth",
              block: "nearest",
              inline: "center"
            });
          }
        }
      });
    }
  },
  mounted() {
    this.checkScrollButtons();
    this.$refs.scrollContainer?.addEventListener(
      "scroll",
      this.checkScrollButtons
    );
    window.addEventListener("resize", this.checkScrollButtons);
  },
  beforeDestroy() {
    this.$refs.scrollContainer?.removeEventListener(
      "scroll",
      this.checkScrollButtons
    );
    window.removeEventListener("resize", this.checkScrollButtons);
  },
  apollo: {
    contract_questions: {
      query: GET_CONTRACT_QUESTIONS,
      variables() {
        const {
          deal: { id: deal_id }
        } = this;
        return { deal_id };
      },
      update({ contract_questions }) {
        console.log({ contract_questions });
        return this.formatQuestions(contract_questions);
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/styles/_mixins.scss";

.modal-container {
  width: 916px;
  height: 719px;
  max-height: calc(100vh - 80px);
  background: #ffffff;
  box-shadow: -1px 0px 48px rgba(0, 0, 0, 0.0812937);
  border-radius: 15px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.modal-header {
  flex: 0 0 auto;
  flex-wrap: wrap;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  padding-bottom: 0;

  .icon {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
}

.modal-body {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0; // Important for nested flex scrolling

  .questions-container {
    flex: 1;
    overflow-y: auto;
    padding: 10px 80px;

    scrollbar-width: thin;
    scrollbar-color: $accent rgba(#cdcccc, 0.3);

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      @include background-opacity(#cdcccc, 0.3);
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $accent;
      border-radius: 5px;
    }
  }

  .navigation-buttons {
    flex: 0 0 auto;
    height: 5rem;
    padding: 1rem 0;
    display: flex;
    justify-content: center;
    gap: 1rem;
    background: white;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    margin-top: auto;

    button {
      margin: 0;
      width: 169px;
      height: 50px;
      font-weight: bold;
      font-size: 16px;
      line-height: 16px;
    }
  }
}

.categories-tabs {
  position: relative;
  width: 100%;
  padding: 0;
  margin: 1rem 0;
  display: flex;
  align-items: center;

  .scroll-button {
    position: relative;
    min-width: 24px;
    width: 24px;
    height: 24px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1;
    transition: all 0.2s ease;
    margin: 0;
    padding: 0;

    &:hover {
      background: #f5f5f5;
      border-color: rgba(0, 0, 0, 0.2);
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
    }

    span {
      font-size: 20px;
      line-height: 1;
      color: #666;
      user-select: none;
    }

    &.left {
      margin-right: -12px;
    }

    &.right {
      margin-left: -12px;
    }
  }

  .scroll-container {
    flex: 1;
    display: flex;
    overflow-x: auto;
    padding: 0.5rem 1rem;
    margin: 0 12px;
    scroll-behavior: smooth;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.category-tab {
  padding: 0.5rem 1rem;
  border-radius: 20px;
  cursor: pointer;
  position: relative;
  font-size: 14px;
  color: #666;
  transition: all 0.3s ease;
  white-space: nowrap;
  margin: 0 0.5rem;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }

  &.active {
    background: $accent;
    color: white;
  }

  .unanswered-indicator {
    position: absolute;
    top: 0;
    right: 0;
    width: 6px;
    height: 6px;
    background: red;
    border-radius: 50%;
    transform: translate(50%, -50%);
  }
}

.modal-header {
  flex-direction: column;
}
</style>
