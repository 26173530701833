<template>
  <div class="modals-container">
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <div class="modal-header">
              <slot name="header">
                <div @click="$emit('close')" class="icon">
                  <ModalCloseIcon :accent="false" />
                </div>
                <div class="title">
                  <h3>
                    {{
                      existingFiles.length > 0
                        ? "Uploaded Contracts"
                        : "Upload a signed contract"
                    }}
                  </h3>
                </div>
              </slot>
            </div>

            <div class="modal-body">
              <slot name="body">
                <div class="details">
                  <div class="file-upload-container">
                    <div v-if="existingFiles.length > 0" class="existing-files">
                      <div class="file-list">
                        <div
                          v-for="file in existingFiles"
                          :key="file.id"
                          class="file-item"
                          @click="viewFile(file)"
                        >
                          <span
                            v-html="getFileIcon(file)"
                            class="file-icon"
                          ></span>
                          <span class="file-name flex-1 overflow-ellipsis">{{
                            file.original_name
                          }}</span>
                          <span class="file-date text-sm text-gray-500">{{
                            formatDate(file.created_at)
                          }}</span>
                          <button class="view-file">
                            <template v-if="loadingFile === file.id">
                              <Loader :size="20" />
                            </template>
                            <template v-else>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="icon icon-tabler icon-tabler-eye"
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <path
                                  stroke="none"
                                  d="M0 0h24v24H0z"
                                  fill="none"
                                />
                                <path
                                  d="M12 12m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"
                                />
                                <path
                                  d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7"
                                />
                              </svg>
                            </template>
                          </button>
                        </div>
                      </div>
                    </div>

                    <template v-if="!existingFiles.length">
                      <div class="file-input-wrapper">
                        <input
                          type="file"
                          @change="handleFileUpload"
                          multiple
                          ref="fileInput"
                          class="file-input"
                          :disabled="uploading"
                        />
                      </div>
                      <div v-if="files.length > 0" class="file-list flex-1">
                        <div
                          v-for="(file, index) in files"
                          :key="index"
                          class="file-item"
                        >
                          <span
                            v-html="getFileIcon(file)"
                            class="file-icon"
                          ></span>
                          <span class="file-name flex-1 overflow-ellipsis">{{
                            file.name
                          }}</span>
                          <span class="file-size">{{
                            formatFileSize(file.size)
                          }}</span>
                          <button
                            @click="removeFile(index)"
                            class="remove-file"
                            :disabled="uploading"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="icon icon-tabler icon-tabler-x"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              fill="none"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            >
                              <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                              />
                              <path d="M18 6l-12 12" />
                              <path d="M6 6l12 12" />
                            </svg>
                          </button>
                        </div>
                      </div>
                      <div
                        v-else
                        class="no-files flex flex-col items-center justify-center gap-5"
                      >
                        <svg
                          width="65"
                          height="65"
                          viewBox="0 0 65 65"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_4938_7923)">
                            <path
                              d="M43.1673 43.1667L32.5007 32.5L21.834 43.1667"
                              stroke="#FF035A"
                              stroke-width="5.33333"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M32.5 32.5V56.5"
                              stroke="#FF035A"
                              stroke-width="5.33333"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M54.8713 49.5491C57.4722 48.1311 59.5268 45.8874 60.7109 43.1721C61.895 40.4567 62.1412 37.4244 61.4105 34.5536C60.6798 31.6828 59.0139 29.1371 56.6757 27.3183C54.3376 25.4994 51.4602 24.511 48.4979 24.5091H45.1379C44.3308 21.387 42.8264 18.4886 40.7378 16.0317C38.6492 13.5748 36.0309 11.6233 33.0795 10.324C30.1282 9.02471 26.9207 8.41136 23.6983 8.53009C20.4758 8.64882 17.3221 9.49654 14.4744 11.0095C11.6267 12.5225 9.15904 14.6613 7.25693 17.2653C5.35483 19.8692 4.06778 22.8705 3.49255 26.0435C2.91733 29.2164 3.06889 32.4785 3.93584 35.5844C4.8028 38.6904 6.36259 41.5594 8.49794 43.9757"
                              stroke="#FF035A"
                              stroke-width="5.33333"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M43.1673 43.1667L32.5007 32.5L21.834 43.1667"
                              stroke="#FF035A"
                              stroke-width="5.33333"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_4938_7923">
                              <rect
                                width="64"
                                height="64"
                                fill="white"
                                transform="translate(0.5 0.5)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                        No files uploaded yet. Click "Upload Files" to begin.
                      </div>
                    </template>

                    <div v-if="uploading" class="upload-progress">
                      <div class="progress-bar">
                        <div
                          class="progress-bar-fill"
                          :style="{ width: `${uploadProgress}%` }"
                        ></div>
                      </div>
                      <div class="progress-text">
                        Uploading: {{ uploadProgress }}%
                      </div>
                    </div>
                    <div v-if="errorMessage" class="error-message">
                      {{ errorMessage }}
                    </div>
                  </div>
                </div>
                <div class="buttons">
                  <button
                    @click="$emit('close')"
                    class="primary outline flex-1"
                    style="width: auto;"
                    :disabled="uploading"
                  >
                    Close
                  </button>
                  <template v-if="!existingFiles.length">
                    <button
                      v-if="files.length === 0"
                      @click="triggerFileInput"
                      class="primary flex-1"
                      style="width: auto;"
                      :disabled="uploading"
                    >
                      Browse file
                    </button>
                    <button
                      v-else
                      @click="uploadFiles"
                      class="primary flex-1"
                      style="width: auto;"
                      :disabled="uploading"
                    >
                      <span v-if="!uploading">Upload</span>
                      <Loader v-else />
                    </button>
                  </template>
                </div>
              </slot>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <!-- Preview Modal -->
    <transition name="modal">
      <div v-if="showPreview" class="modal-mask" style="z-index: 1000;">
        <div class="modal-wrapper">
          <div class="modal-container preview-container">
            <div class="modal-header">
              <div @click="closePreview" class="icon">
                <ModalCloseIcon :accent="false" />
              </div>
            </div>

            <div class="modal-body preview-body">
              <template v-if="previewType === 'application/pdf'">
                <iframe
                  :src="previewUrl"
                  type="application/pdf"
                  width="100%"
                  height="100%"
                  style="border: none;"
                ></iframe>
              </template>
              <template v-else-if="previewType === 'application/msword'">
                <iframe
                  :src="previewUrl"
                  width="100%"
                  height="100%"
                  style="border: none;"
                  frameborder="0"
                ></iframe>
              </template>
              <template
                v-else-if="previewType && previewType.startsWith('image/')"
              >
                <img
                  :src="previewUrl"
                  style="max-width: 100%; max-height: 100%; object-fit: contain;"
                />
              </template>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import axios from "axios";
import ModalCloseIcon from "@/assets/icons/Modal-Close.vue";
import Loader from "@/components/Loader.vue";
import { uploadFiles } from "@/services/uploadService";
import GET_SONG_FILES from "@/api/queries/GET_SONG_FILES.gql";
import GET_SONG_UPLOADED_CONTRACTS from "@/api/queries/GET_SONG_UPLOADED_CONTRACTS.gql";

export default {
  name: "ViewContract",
  components: {
    ModalCloseIcon,
    Loader
  },
  props: {
    deal: {
      type: Object,
      required: true
    },
    song: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      files: [],
      existingFiles: [],
      maxFileSize: 20 * 1024 * 1024, // 20MB
      allowedFormats: [
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "image/jpeg",
        "image/png"
      ],
      errorMessage: "",
      uploading: false,
      uploadProgress: 0,
      uploadedFiles: [],
      maxRetries: 3,
      previewUrl: null,
      previewType: null,
      showPreview: false,
      loadingFile: null // Track which file is being loaded
    };
  },

  async created() {
    await this.fetchExistingFiles();
  },

  methods: {
    triggerFileInput() {
      this.$refs.fileInput.click();
    },

    validateFile(file) {
      if (!this.allowedFormats.includes(file.type)) {
        throw new Error(
          `File "${file.name}" is not in an allowed format. Please upload PDF, DOC, DOCX, JPG, or PNG files only.`
        );
      }

      if (file.size > this.maxFileSize) {
        throw new Error(
          `File "${file.name}" exceeds the maximum file size of 20MB.`
        );
      }

      return true;
    },

    async handleFileUpload(event) {
      const newFiles = Array.from(event.target.files);
      this.errorMessage = "";

      try {
        newFiles.forEach(file => this.validateFile(file));
        this.files.push(...newFiles);
      } catch (error) {
        this.errorMessage = error.message;
      }

      // Clear the file input
      event.target.value = "";
    },

    removeFile(index) {
      this.files.splice(index, 1);
      this.errorMessage = "";
    },

    async fetchExistingFiles() {
      try {
        const { data } = await this.$apollo.query({
          query: GET_SONG_UPLOADED_CONTRACTS,
          variables: {
            song_id: this.song.id
          }
        });
        this.existingFiles = data.files;
      } catch (error) {
        console.error("Error fetching files:", error);
      }
    },

    async uploadFiles() {
      if (this.files.length === 0) {
        this.errorMessage = "Please select files to upload";
        return;
      }

      this.uploading = true;
      this.uploadProgress = 0;
      this.errorMessage = "";

      try {
        // Upload the files
        const response = await uploadFiles(
          this.files,
          this.song.id,
          progress => {
            this.uploadProgress = progress;
          }
        );

        // Clear the upload queue
        this.files = [];

        // Fetch the updated file list
        const { data } = await this.$apollo.query({
          query: GET_SONG_UPLOADED_CONTRACTS,
          variables: {
            song_id: this.song.id
          },
          fetchPolicy: "network-only" // Force a fresh fetch from server
        });

        // Update the existing files list
        this.existingFiles = data.files;

        // Emit the upload complete event with the updated files
        this.$emit("upload-complete", data.files);
      } catch (error) {
        if (error.response?.status === 401) {
          this.$router.push("/login");
        } else {
          this.errorMessage =
            error.response?.data?.message ||
            "Error uploading files. Please try again.";
        }
      } finally {
        this.uploading = false;
        this.uploadProgress = 0;
      }
    },

    getFileIcon(file) {
      const fileName = file.original_name || file.name;
      const extension = fileName
        .split(".")
        .pop()
        .toLowerCase();
      switch (extension) {
        case "pdf":
          return `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="currentColor" d="M7 3H17C18.1046 3 19 3.89543 19 5V19C19 20.1046 18.1046 21 17 21H7C5.89543 21 5 20.1046 5 19V5C5 3.89543 5.89543 3 7 3ZM7 5V19H17V5H7ZM10 10V8H14V10H10ZM10 12V14H14V12H10ZM10 16V18H14V16H10Z"/></svg>`;
        case "doc":
        case "docx":
          return `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="currentColor" d="M14 2H6C4.89543 2 4 2.89543 4 4V20C4 21.1046 4.89543 22 6 22H18C19.1046 22 20 21.1046 20 20V8L14 2ZM18 20H6V4H13V9H18V20ZM9 13V15H15V13H9ZM9 17V19H15V17H9Z"/></svg>`;
        case "xls":
        case "xlsx":
          return `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="currentColor" d="M14 2H6C4.89543 2 4 2.89543 4 4V20C4 21.1046 4.89543 22 6 22H18C19.1046 22 20 21.1046 20 20V8L14 2ZM18 20H6V4H13V9H18V20ZM10 19H8V17H10V19ZM16 19H14V17H16V19ZM10 15H8V13H10V15ZM16 15H14V13H16V15Z"/></svg>`;
        case "jpg":
        case "jpeg":
        case "png":
          return `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="currentColor" d="M19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3ZM5 19V5H19V19H5ZM15 15V13.5C15 12.6716 14.3284 12 13.5 12C14.3284 12 15 11.3284 15 10.5V9H9V15H15ZM13 13H11V11H13V13Z"/></svg>`;
        default:
          return `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="currentColor" d="M14 2H6C4.89543 2 4 2.89543 4 4V20C4 21.1046 4.89543 22 6 22H18C19.1046 22 20 21.1046 20 20V8L14 2ZM18 20H6V4H13V9H18V20Z"/></svg>`;
      }
    },
    formatFileSize(bytes) {
      if (bytes === 0) return "0 Bytes";
      const k = 1024;
      const sizes = ["Bytes", "KB", "MB", "GB"];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
    },
    formatDate(dateString) {
      return new Date(dateString).toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric"
      });
    },
    async viewFile(file) {
      try {
        this.loadingFile = file.id;
        const token = this.$store.getters["account/getApolloToken"];
        const fileUrl = `https://hasura-remote.nvoko.com/api/s3/${file.key}`;

        // For PDFs and images, we'll fetch the blob for direct viewing
        if (
          file.mime_type === "application/pdf" ||
          file.mime_type.startsWith("image/")
        ) {
          const response = await axios({
            method: "get",
            url: fileUrl,
            headers: {
              Authorization: `Bearer ${token}`
            },
            responseType: "blob"
          });

          const url = window.URL.createObjectURL(
            new Blob([response.data], { type: file.mime_type })
          );
          this.previewUrl = url;
          this.previewType = file.mime_type;
          this.showPreview = true;
        }
        // For Word documents and other files, trigger download
        else {
          const response = await axios({
            method: "get",
            url: fileUrl,
            headers: {
              Authorization: `Bearer ${token}`
            },
            responseType: "blob"
          });

          const url = window.URL.createObjectURL(
            new Blob([response.data], { type: file.mime_type })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", file.original_name || "document");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        }
      } catch (error) {
        console.error("Error viewing file:", error);
        this.errorMessage = "Error viewing file. Please try again.";
      } finally {
        this.loadingFile = null;
      }
    },

    closePreview() {
      if (this.previewUrl) {
        window.URL.revokeObjectURL(this.previewUrl);
      }
      this.previewUrl = null;
      this.previewType = null;
      this.showPreview = false;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/styles/_mixins.scss";

.modal-container {
  max-width: 600px;
  width: 100%;
  height: 50vh;
  background: #ffffff;
  box-shadow: -1px 0px 48px rgba(0, 0, 0, 0.0812937);
  border-radius: 15px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.error-message {
  color: #dc3545;
  margin-top: 10px;
  text-align: center;
  font-size: 14px;
}

.modal-header {
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 1.5rem 32px;

  .icon {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .title {
    display: flex;
    width: 100%;
    justify-content: center;

    .avatar {
      width: 123px;
      height: 123px;
      overflow: hidden;
      border-radius: 15px;

      img {
        object-fit: cover;
      }
    }

    h2 {
      margin-left: 32px;
      font-weight: 500;
      font-size: 40px;
      line-height: 58px;
      letter-spacing: -0.666667px;
      color: $text;

      span {
        font-weight: 700;
      }
    }
  }
}

.modal-body {
  width: 100%;
  height: calc(100% - 158px);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 60px;
  padding-top: 0;

  .details {
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-y: scroll;
    margin-bottom: 1.5rem;
    scrollbar-width: thin;
    scrollbar-color: $accent rgba(#cdcccc, 0.3);

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      @include background-opacity(#cdcccc, 0.3);
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $accent;
      border-radius: 5px;
    }

    .text {
      ::v-deep p {
        padding: 10px 0;
      }

      ::v-deep h1 {
        font-size: 2em;
      }

      ::v-deep h2 {
        font-size: 1.5em;
      }
    }

    .signatures {
      margin: 1rem;
      display: flex;
      justify-content: space-around;

      div {
        display: flex;
        flex-direction: column;

        .signature {
          font-size: 2rem;
        }
      }
    }
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding: 0 40px;
    margin-bottom: -2.5rem;

    button {
      width: 136px;
      height: 44px;
      margin: 0 16px;
      font-weight: 300;
      padding: 0 8px;
    }
  }
}

.file-upload-container {
  padding: 0 1px;
  width: 100%;
}

.file-input-wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.file-input {
  display: none;
}

.upload-button {
  background-color: $accent;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;

  &:hover {
    background-color: darken($accent, 10%);
  }

  i {
    margin-right: 8px;
  }
}

.file-list {
  // max-height: 300px;

  overflow-y: auto;
  width: 100%;
  overflow-x: hidden;
}

.file-item {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: white;
  border-radius: 4px;
  margin-bottom: 10px;
  border: 1px solid #cdcccc;
  width: 100%;
  gap: 10px;
  cursor: pointer;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

  .file-icon {
    flex-shrink: 0;
    width: 24px;
  }

  .file-name {
    flex: 1;
    min-width: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 8px;
  }

  .file-date {
    flex-shrink: 0;
    margin: 0 8px;
  }

  .view-file {
    flex-shrink: 0;
    opacity: 0;
    transition: opacity 0.2s ease;
    padding: 8px;
    border-radius: 4px;
    background: none;
    border: none;
    cursor: pointer;

    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }

  &:hover .view-file {
    opacity: 1;
  }

  .remove-file {
    flex-shrink: 0;
    opacity: 0.6;
    transition: opacity 0.2s ease;
    padding: 8px;
    border-radius: 4px;
    background: none;
    border: none;
    cursor: pointer;

    &:hover:not(:disabled) {
      background-color: rgba(0, 0, 0, 0.05);
    }

    &:disabled {
      cursor: not-allowed;
    }
  }
}

.no-files {
  text-align: center;
  font-style: italic;
  cursor: pointer;
}

.upload-progress {
  margin-top: 1rem;
  width: 100%;

  .progress-bar {
    width: 100%;
    height: 4px;
    background-color: #e9ecef;
    border-radius: 2px;
    overflow: hidden;

    .progress-bar-fill {
      height: 100%;
      background-color: $accent;
      transition: width 0.3s ease;
    }
  }

  .progress-text {
    margin-top: 0.5rem;
    text-align: center;
    font-size: 14px;
    color: $text;
  }
}

.file-input:disabled,
button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.file-item {
  &:hover {
    .remove-file:not(:disabled) {
      opacity: 1;
    }
  }

  .remove-file {
    opacity: 0.6;
    transition: opacity 0.2s ease;

    &:disabled {
      cursor: not-allowed;
    }
  }
}

.existing-files {
  max-height: calc(50vh - 200px);
  overflow-y: auto;
  margin-bottom: 1rem;
  padding: 0 1px;
}

.file-item {
  cursor: pointer;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

  .view-file {
    opacity: 0;
    transition: opacity 0.2s ease;
  }

  &:hover .view-file {
    opacity: 1;
  }
}

.preview-container {
  max-width: 90vw;
  width: 90vw;
  height: 90vh;
  padding: 1rem;
}

.preview-body {
  height: calc(100% - 60px) !important;
  padding: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.03);
  border-radius: 8px;
}

.modals-container {
  position: relative;
  z-index: 1000;

  .modal-mask {
    position: fixed;
    z-index: 1000;
  }
}
</style>
